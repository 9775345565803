import { Title } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogActions from "@mui/material/DialogActions";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Divider, Icon, Input, TextField } from "@mui/material";
import { styled } from "@mui/system";
import { Paragraph } from "app/components/Typography";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SnackBar from "Components/Snackbar/Snackbar";
import "./PopUpAdd.css";
import { React } from "react";
import { MuiTelInput } from "mui-tel-input";

import DropDown from "Components/DropDown/DropDown";
import { AddNewsLetter } from "Redux/NewsLetter/NewsLetterActions";
const DialogTitleRoot = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
  "& .closeButton": {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogTitle = (props) => {
  const { children, onClose } = props;
  return (
    <DialogTitleRoot disableTypography>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className="closeButton"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitleRoot>
  );
};

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  "&.root": { padding: theme.spacing(2) },
}));

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  "&.root": { margin: 0, padding: theme.spacing(1) },
}));

const PopUpAdd = ({ setopen, open }) => {
  // const handleClickOpen = () => setOpen(true);

  const handleClose = () => setopen(false);
  const dispatch = useDispatch();
  const handlecreate = () => {
    const news = {
      subject: data.subject,
      message: data.message,
    };
    dispatch(AddNewsLetter(news));
    handleClose();
  };
  const [data, setdata] = useState({ subject: "", message: "" });
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div className="cont">
            {" "}
            <Icon>mail</Icon> Send A News Letter
          </div>
        </DialogTitle>

        <DialogContent dividers>
          <div className="popup_div">
            <div className="mail_cont">
              <TextField
                id="outlined-basic"
                label="Subject"
                variant="outlined"
                className="mail_input"
                value={data.subject}
                onChange={(e) => {
                  setdata({ ...data, subject: e.target.value });
                }}
              />
              <div
                className="popup_body"
                style={{ marginTop: "1rem", marginBottom: "1rem" }}
              >
                <textarea
                  style={{
                    width: "100%",
                    height: "150px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    padding: "10px",
                    resize: "none",
                  }}
                  placeholder="Message"
                  value={data.message}
                  onChange={(e) => {
                    setdata({ ...data, message: e.target.value });
                  }}
                />
              </div>
            </div>
            <Divider />
            <div className="btn_cont">
              <Button
                variant="contained"
                color={
                  data.message === "" || data.subject === ""
                    ? "secondary"
                    : "primary"
                }
                onClick={handlecreate}
                disabled={
                  data.message === "" || data.subject === "" ? true : false
                }
              >
                {" "}
                Send
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PopUpAdd;
