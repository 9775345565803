import { Title } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogActions from "@mui/material/DialogActions";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Divider, Icon, Input, TextField } from "@mui/material";
import { styled } from "@mui/system";
import { Paragraph } from "app/components/Typography";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GetSingleStudent } from "Redux/Students/StudentsActions";
import SnackBar from "Components/Snackbar/Snackbar";
import "./PopupDel.css";
import { React } from "react";
import { MuiTelInput } from "mui-tel-input";
import DropDown2 from "./DropDown2";

const DialogTitleRoot = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
  "& .closeButton": {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogTitle = (props) => {
  const { children, onClose } = props;
  return (
    <DialogTitleRoot disableTypography>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className="closeButton"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitleRoot>
  );
};

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  "&.root": { padding: theme.spacing(2) },
}));

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  "&.root": { margin: 0, padding: theme.spacing(1) },
}));

const PopUpDel = ({
  setopen,
  open,
  func,
  role,
  sales,
  setselected,
  selected,
  type,
}) => {
  // const handleClickOpen = () => setOpen(true);

  const handleClose = () => setopen(false);
  const dispatch = useDispatch();

  const handledel = () => {
    dispatch(func);
    setopen(false);
  };
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <div className="cont"> Confirm Deletion</div>
        </DialogTitle>

        <DialogContent dividers>
          {role === "sales" ? (
            <div className="popup_div">
              {sales.length <= 1 ? (
                <div className="popup_div_cont">
                  {type === "couns" ? (
                    <>You Cannot Delete the last Counselor</>
                  ) : (
                    <>You Cannot Delete the last Salesperson</>
                  )}
                </div>
              ) : (
                <>
                  {type === "couns" ? (
                    <div className="popup_div_cont">
                      Before deleting this Counselor, make sure to assign the
                      students to another counselor.
                    </div>
                  ) : (
                    <div className="popup_div_cont">
                      Assign the sales leads to another salesperson before
                      deleting this record.
                    </div>
                  )}
                </>
              )}
              {sales.length >= 1 && (
                <div className="drop_cont">
                  <DropDown2
                    title={
                      type === "couns" ? "Choose Counselor" : "Choose Sales"
                    }
                    suggestions={sales}
                    setselected={setselected}
                    selected={selected}
                  />
                </div>
              )}

              <div className="popup_btn_cont">
                <div className="btn_cont">
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      selected?.value === "" || selected === null ? true : false
                    }
                    // className="btn"
                    width="100%"
                    onClick={() => handledel()}
                  >
                    Delete & Reassign
                  </Button>
                </div>
                <div className="btn_cont">
                  <Button
                    variant="contained"
                    color="secondary"
                    className="btn"
                    onClick={() => setopen(false)}
                  >
                    No
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="popup_div">
              <div className="popup_div_cont">
                Are you sure you want to delete this Record ?
              </div>

              <div className="popup_btn_cont">
                <div className="btn_cont">
                  <Button
                    variant="contained"
                    color="primary"
                    className="btn"
                    onClick={() => handledel()}
                  >
                    Yes
                  </Button>
                </div>
                <div className="btn_cont">
                  <Button
                    variant="contained"
                    color="error"
                    className="btn"
                    onClick={() => setopen(false)}
                  >
                    No
                  </Button>
                </div>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PopUpDel;
