import React, { useEffect, useState } from "react";
import "./Profile.css";
import { Autocomplete, Button, TextField } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useDispatch, useSelector } from "react-redux";
import { editUser, editUserPassword } from "Redux/User/UserActions";
import { languagesList } from "app/utils/utils";
import { getAllCountries } from "Redux/Location/LocationActions";

function Profile() {
  const dispatch = useDispatch();

  const [selectedDate, setSelectedDate] = useState(null);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState(null);
  const [country, setCountry] = useState(null);
  const [nationality, setNationality] = useState(null);
  const [language, setLanguage] = useState(null);
  const [phone, setPhone] = useState();

  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [passError, setpassError] = useState("");
  const { user, changePasswordError, changePasswordSuccess, editUserError } =
    useSelector((state) => state.User);

  useEffect(() => {
    dispatch(getAllCountries());
  }, []);

  const { countries } = useSelector((state) => state.LocationReducer);
  const handlePhone = (newVal) => {
    setPhone(newVal);
  };

  useEffect(() => {
    if (changePasswordSuccess) {
      setCurrentPass("");
      setNewPass("");
      setConfirmPass("");
    }
  }, [changePasswordSuccess]);

  useEffect(() => {
    if (changePasswordError) {
      setpassError(changePasswordError);
    }
  }, [changePasswordError]);

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      user.date_of_birth
        ? setSelectedDate(new Date(user.date_of_birth))
        : setSelectedDate(null);
      setName(user.first_name);
      setLastName(user.last_name);
      setEmail(user.email);
      setGender(user.gender);
      setCountry({ name: user.country_of_residence });
      setNationality({ nationality: user.nationality });
      setLanguage({
        value: user.preferred_language,
        label: user.preferred_language,
      });
      setPhone(user.phone);
    }
  }, [user]);

  useEffect(() => {
    confirmPass != newPass
      ? setpassError("Passwords doesn't match")
      : setpassError("");
  }, [confirmPass, newPass]);

  return (
    <div className="profile_main">
      <h1>Personal Data</h1>
      <div className="profile_sections">
        <div className="profile_single_section">
          <TextField
            id="outlined-basic"
            label="First Name"
            style={{ width: "100%" }}
            variant="outlined"
            className="profile_textfield"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="Last Name"
            style={{ width: "100%" }}
            variant="outlined"
            className="profile_textfield"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="Email Name"
            style={{ width: "100%" }}
            variant="outlined"
            className="profile_textfield"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="profile_single_section">
          <Autocomplete
            options={["Male", "Female"]}
            style={{ width: "100%" }}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Gender"
                variant="outlined"
                fullWidth
              />
            )}
            value={gender}
            onChange={(e, newValue) => setGender(newValue)}
          />
          <Autocomplete
            options={countries}
            style={{ width: "100%" }}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Country"
                variant="outlined"
                fullWidth
              />
            )}
            value={country}
            onChange={(e, newValue) => setCountry(newValue)}
          />
          <Autocomplete
            options={countries}
            style={{ width: "100%" }}
            getOptionLabel={(option) => option.nationality}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Nationality"
                variant="outlined"
                fullWidth
              />
            )}
            value={nationality}
            onChange={(e, newValue) => setNationality(newValue)}
          />
        </div>
        <div className="profile_single_section">
          <Autocomplete
            options={languagesList}
            style={{ width: "100%" }}
            getOptionLabel={(option) => option.value}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Preferred Language"
                variant="outlined"
                fullWidth
              />
            )}
            value={language}
            onChange={(e, newValue) => setLanguage(newValue)}
          />
          <MuiTelInput
            fullWidth={true}
            label="Phone Number"
            value={phone}
            onChange={handlePhone}
            langOfCountryName="en"
            // style={{ width: "190px" }}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date of Birth"
              value={selectedDate}
              onChange={(newValue) => {
                setSelectedDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} style={{ width: "100%" }} />
              )}
              inputFormat="dd-M-yyyy"
              maxDate={new Date()}
            />
          </LocalizationProvider>
        </div>
        {editUserError && <p style={{ color: "red" }}>{editUserError}</p>}
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            dispatch(
              editUser({
                ...(name != "" &&
                  name != user.first_name && { first_name: name }),
                ...(lastName != "" &&
                  lastName != user.last_name && { last_name: lastName }),
                ...(email != "" && email != user.email && { email: email }),
                ...(gender != null &&
                  gender != user.gender && { gender: gender }),
                ...(country != null &&
                  country.name != user.country_of_residence && {
                    country_of_residence: country.name,
                  }),
                ...(nationality != null &&
                  nationality.nationality != user.nationality && {
                    nationality: nationality.nationality,
                  }),
                ...(language != null &&
                  language.value != user.preferred_language && {
                    preferred_language: language.value,
                  }),
                ...(phone != "" && phone != user.phone && { phone: phone }),
                ...(selectedDate != null &&
                  selectedDate != user.date_of_birth && {
                    date_of_birth: selectedDate,
                  }),
              })
            )
          }
        >
          Save Changes
        </Button>
      </div>
      <div className="profile_security_section">
        <h1></h1>
        <div className="profile_security_section_main">
          <TextField
            id="outlined-basic"
            label="Current Password"
            type="password"
            style={{ width: "100%" }}
            variant="outlined"
            className="profile_textfield"
            value={currentPass}
            onChange={(e) => setCurrentPass(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="New Password"
            type="password"
            style={{ width: "100%" }}
            variant="outlined"
            className="profile_textfield"
            value={newPass}
            onChange={(e) => {
              setNewPass(e.target.value);
            }}
          />
          <TextField
            id="outlined-basic"
            type="password"
            label="Confirm New Password"
            style={{ width: "100%" }}
            variant="outlined"
            className="profile_textfield"
            value={confirmPass}
            onChange={(e) => {
              setConfirmPass(e.target.value);
            }}
          />
          {passError && <p style={{ color: "red" }}>{passError}</p>}
        </div>
        <Button
          variant="contained"
          color="primary"
          style={{ width: "100%", marginTop: "1rem" }}
          onClick={() => {
            setpassError("");
            if (currentPass && newPass && confirmPass) {
              if (newPass == confirmPass) {
                dispatch(
                  editUserPassword({
                    oldPassword: currentPass,
                    newPassword: newPass,
                    confirmPassword: confirmPass,
                  })
                );
              }
            }
          }}
        >
          Change Password
        </Button>
      </div>
    </div>
  );
}

export default Profile;
