import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Icon,
  IconButton,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Universities.css";
import { MatxLoading } from "app/components";
import MuiDialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  editUniversity,
  GetAllUniversities,
} from "Redux/Universities/UniversitiesActions";
import Pagination from "Components/Pagination/Pagination";
import UniversityView from "./UniveristyAct/UniversityView";
import { useNavigate } from "react-router-dom";

const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));
function UniversitiesTable() {
  const dispatch = useDispatch();
  const TextField = styled(TextValidator)(() => ({
    width: "100%",
    marginBottom: "16px",
  }));

  const DialogTitleRoot = styled(MuiDialogTitle)(({ theme }) => ({
    margin: 0,
    padding: theme.spacing(2),
    "& .closeButton": {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }));

  const navigate = useNavigate();

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [openView, setOpenView] = useState(false);
  const [uniIndex, setUniIndex] = useState(-1);
  const [openAdd, setOpenAdd] = useState(false);

  useEffect(() => {
    dispatch(GetAllUniversities(limit, offset));
  }, []);

  const {
    getuniversitiesLoading,
    getuniversitiesError,
    getuniversitiesSuccess,
    universities,
    edituniversityLoading,
    edituniversityError,
    edituniversitySuccess,
  } = useSelector((state) => state.UniversitiesReducer);

  const handleChange = (id) => (event) => {
    dispatch(
      editUniversity(
        id,
        {
          accepting_applications: event.target.checked.toString(),
        },
        limit,
        offset
      )
    );
  };

  useEffect(() => {
    dispatch(GetAllUniversities(limit, offset));
  }, [limit, offset]);
  return (
    <Box width="95%" overflow="auto" margin="auto" marginTop="2rem">
      {getuniversitiesLoading || edituniversityLoading ? (
        <MatxLoading />
      ) : (
        <>
          <div className="header_major_grp">
            <h1>Universities </h1>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                navigate("/dashboard/universities/add");
              }}
            >
              Add Universities
            </Button>
          </div>
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell align="left">Name</TableCell>

                <TableCell align="center">Location</TableCell>
                <TableCell align="center">Languages</TableCell>
                <TableCell align="center">Founded</TableCell>
                <TableCell align="center">Status</TableCell>

                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            {universities &&
              universities.universities?.length > 0 &&
              universities.universities.map((university, index) => (
                <TableBody>
                  <TableRow key={index}>
                    <TableCell align="left">
                      <div className="uni_header">
                        <img
                          className="university_logo"
                          src={
                            process.env.REACT_APP_API +
                            "/files/universities/" +
                            university.logo
                          }
                        />
                        <div className="uni_name">{university.name}</div>
                      </div>
                    </TableCell>

                    <TableCell align="center">
                      {university.country}, {university.city}
                    </TableCell>
                    <TableCell align="center">
                      {university.languages.map((lang, i) => {
                        if (i == university.languages.length - 1) {
                          return lang;
                        } else {
                          return lang + ", ";
                        }
                      })}
                    </TableCell>
                    <TableCell align="center">
                      {university.summary.Founded}
                    </TableCell>

                    <TableCell align="center">
                      <FormControlLabel
                        label={
                          university.accepting_applications
                            ? "Recruiting"
                            : "Full"
                        }
                        control={
                          <Switch
                            color="primary"
                            value="checkedB"
                            checked={university.accepting_applications}
                            onChange={handleChange(university.id)}
                          />
                        }
                      />
                    </TableCell>

                    <TableCell align="right">
                      <IconButton
                        onClick={() => {
                          navigate(
                            "/dashboard/universities/edit/" + university.id
                          );
                        }}
                      >
                        <Icon color="primary">edit</Icon>
                      </IconButton>{" "}
                      <IconButton
                        onClick={() => {
                          navigate(
                            "/dashboard/universities/singleUni/" + university.id
                          );
                        }}
                      >
                        <Icon>visibility</Icon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
          </StyledTable>
        </>
      )}
      {edituniversityError && (
        <div className="error_div">{edituniversityError}</div>
      )}
      <div className="pagination_div">
        <Pagination
          limit={limit}
          offset={offset}
          setOffset={setOffset}
          setLimit={setLimit}
          pageNumber={page}
          setPageNumber={setPage}
          hasPrev={universities.previous}
          hasNext={universities.next}
        />
      </div>
    </Box>
  );
}

export default UniversitiesTable;
