import { Autocomplete, Button, TextField } from "@mui/material";
import { get_Degrees } from "Redux/Degrees/DegreesActions";
import { getAllMajorGroups } from "Redux/MajorCategories/MajorCategoriesActions";
import {
  addMajor,
  addMajorDegree,
} from "Redux/Universities/UniversitiesActions";
import { set } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
function AddMajorDegree({
  majorId,
  doneFac,
  setDoneFac,
  setAddedFac,
  addedFac,
}) {
  const dispatch = useDispatch();
  const [nbMaj, setNbMaj] = useState(1);
  const [majors, setMajors] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [error, setError] = useState("");
  const [done, setDone] = useState(false);
  const { addMajorDegreeLoading, addMajorDegreeError, addMajorDegreeSuccess } =
    useSelector((state) => state.UniversitiesReducer);
  const { degrees, getDegreesLoading, getDegreesError } = useSelector(
    (state) => state.degreeReducer
  );
  const [selectedDegree, setSelectedDegree] = useState(null);
  const [selectedTution, setSelectedTution] = useState(null);
  const [allDegrees, setAllDegrees] = useState([]);
  const [description, setDescription] = useState("");
  const [cost, setCost] = useState("");
  const [visaReq, setVisaReq] = useState("");
  const [duration, setDuration] = useState("");
  const [entryReq, setEntryReq] = useState("");
  const [intakes, setIntakes] = useState("");
  useEffect(() => {
    dispatch(get_Degrees());
  }, []);

  const handleSave = () => {
    if (
      !selectedDegree ||
      !selectedTution ||
      !description ||
      !cost ||
      !visaReq ||
      !duration ||
      !entryReq ||
      !intakes
    ) {
      setError("Please fill all the fields");
    } else {
      setError("");

      const visa = visaReq.split(",");
      const entry = entryReq.split(",");
      const maj = {
        major: majorId,
        degree: selectedDegree.id,
        tuition: parseInt(selectedTution),
        about: description,
        living_cost: parseInt(cost),
        visa_requirements: visa,
        duration: parseInt(duration),
        entry_requirements: entry,
        intakes: parseInt(intakes),
      };
      setAllDegrees(
        degrees?.filter((degree) => degree.id !== selectedDegree.id)
      );
      setClicked(true);
      dispatch(addMajorDegree(maj));
    }
  };

  useEffect(() => {
    if (addMajorDegreeSuccess && clicked) {
      setClicked(false);
      setDone(true);

      //add the degrees to the last major in the last faculty in the list
      const updatedObject = [...addedFac];
      const lastFaculty = updatedObject[updatedObject.length - 1];

      const lastMajor = lastFaculty.majors[lastFaculty.majors.length - 1];

      if (lastMajor.degrees) {
        lastMajor.degrees = [
          ...lastMajor.degrees,
          { name: selectedDegree.name },
        ];
      } else {
        lastMajor.degrees = [{ name: selectedDegree.name }];
      }

      setAddedFac(updatedObject);
    }
  }, [addMajorDegreeSuccess, clicked]);

  const plusMajorDegree = () => {
    setNbMaj(nbMaj + 1);
    //RESET
    setSelectedDegree(null);
    setSelectedTution(null);
    setDescription("");
    setCost("");
    setVisaReq("");
    setDuration("");
    setEntryReq("");
    setIntakes("");
    setDone(false);
  };
  useEffect(() => {
    if (degrees) {
      setAllDegrees(degrees);
    }
  }, [degrees]);
  useEffect(() => {
    if (addMajorDegreeError) {
      setError(addMajorDegreeError);
    }
  }, [addMajorDegreeError]);
  return (
    <div className="add_major_main_div2">
      <div className="add_major_header">
        <h3>Add Degree Details</h3>
        {done ? (
          <>
            <Button variant="contained" onClick={plusMajorDegree}>
              Add Degree Details
            </Button>

            <Button
              variant="contained"
              color="secondary"
              onClick={() =>
                setDoneFac({
                  step3: true,
                })
              }
            >
              Finish adding degrees
            </Button>
          </>
        ) : (
          addedFac[addedFac?.length - 1].majors[
            addedFac[addedFac?.length - 1].majors?.length - 1
          ]?.degrees?.length > 0 && (
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setDone(true);
              }}
            >
              Cancel
            </Button>
          )
        )}
      </div>

      <div className="add_major_body">
        {!done && (
          <div className="add_major_body_div">
            {allDegrees?.length > 0 && (
              <Autocomplete
                style={{ width: "30%" }}
                options={allDegrees}
                getOptionLabel={(option) => option.name}
                disabled={done}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Degree"
                    variant="outlined"
                    fullWidth
                  />
                )}
                value={selectedDegree}
                onChange={(e, value) => {
                  setSelectedDegree(value);
                }}
              />
            )}
            <TextField
              disabled={done}
              style={{ width: "30%" }}
              label="Yearly Tution Fees"
              type="number"
              inputProps={{ min: 0 }}
              value={selectedTution}
              onChange={(e) => setSelectedTution(e.target.value)}
            />
            <TextField
              disabled={done}
              style={{ width: "30%" }}
              label="Yearly Living Cost"
              type="number"
              inputProps={{ min: 0 }}
              value={cost}
              onChange={(e) => setCost(e.target.value)}
            />
            <TextField
              disabled={done}
              style={{ width: "30%" }}
              label="Yearly Duration"
              type="number"
              inputProps={{ min: 0 }}
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            />
            <TextField
              disabled={done}
              style={{ width: "30%" }}
              label="Yearly Intakes"
              type="number"
              inputProps={{ min: 0 }}
              value={intakes}
              onChange={(e) => setIntakes(e.target.value)}
            />
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
                marginTop: "-1rem",
              }}
            >
              <h4>Visa Requirements: </h4>

              <textarea
                className="text_area_uni"
                placeholder="Visa requirements seperated by comma e.g: IELTS,TOEFL"
                disabled={done}
                value={visaReq}
                onChange={(e) => setVisaReq(e.target.value)}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
                marginTop: "-1rem",
              }}
            >
              <h4>Entry Requirements: </h4>
              <textarea
                className="text_area_uni"
                placeholder="Entry requirements seperated by comma e.g: IELTS,TOEFL"
                disabled={done}
                value={entryReq}
                onChange={(e) => setEntryReq(e.target.value)}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
                marginTop: "-1rem",
              }}
            >
              <h4>Major Degree Description: </h4>
              <textarea
                className="text_area_uni"
                placeholder="Major Degree Description"
                disabled={done}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>{" "}
            {error && <div className="error_div">{error}</div>}
            {!done && (
              <Button
                variant="contained"
                onClick={() => handleSave()}
                disabled={addMajorDegreeLoading}
              >
                Save
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default AddMajorDegree;
