import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import "../Universities.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDegree,
  getMajorDegree,
  getMajors,
  getSingleUni,
} from "Redux/Universities/UniversitiesActions";
import { useDispatch, useSelector } from "react-redux";
import { MatxLoading } from "app/components";
import back from "../../../Assets/Images/arrow1.webp";
function UniversityView() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [selectedFaculty, setSelectedFaculty] = useState(null);
  const [selectedMajor, setselectedMajor] = useState(null);
  const [selectedDegree, setSelectedDegree] = useState(null);

  useEffect(() => {
    if (selectedFaculty) {
      dispatch(getMajors(selectedFaculty.id));
      setselectedMajor();
      setSelectedDegree();
    }
  }, [selectedFaculty]);

  useEffect(() => {
    if (selectedMajor) {
      dispatch(getDegree(selectedMajor.id));
      setSelectedDegree();
    }
  }, [selectedMajor]);

  useEffect(() => {
    dispatch(getSingleUni(id));
  }, [id]);

  useEffect(() => {
    if (selectedFaculty && selectedMajor && selectedDegree) {
      dispatch(getMajorDegree(selectedMajor.id, selectedDegree.id));
    }
  }, [selectedFaculty, selectedMajor, selectedDegree]);

  const {
    getuniversityLoading,
    getuniversityError,
    getuniversitySuccess,
    singleUniversity,
    Majors,
    Degrees,
    MajorDegree,
  } = useSelector((state) => state.UniversitiesReducer);
  return (
    <Box
      width="95%"
      overflow="auto"
      margin="auto"
      marginTop="2rem"
      marginBottom="2rem"
    >
      {getuniversityLoading ? (
        <MatxLoading />
      ) : (
        <div className="uni_view_div">
          <div className="uni_header_div">
            <div className="uni_header">
              <img
                src={back}
                className="back_icon"
                onClick={() => navigate("/dashboard/universities")}
              />
              <img
                className="university_logo"
                src={
                  process.env.REACT_APP_API +
                  "/files/universities/" +
                  singleUniversity?.logo
                }
              />
              <div className="uni_name">{singleUniversity?.name}</div>
            </div>
            <div className="uni_info_item3">
              <span className="uni_info_item_title">Status:</span>
              <span
                className="uni_info_item_value"
                style={{
                  color: singleUniversity?.accepting_applications
                    ? "#00a67e"
                    : "red",
                }}
              >
                {singleUniversity?.accepting_applications
                  ? "Accepting Applications"
                  : "Full"}
              </span>
            </div>
          </div>

          <DialogContent dividers>
            <div className="uni_info">
              <div className="uni_info_item2">
                <img
                  className="uni_image"
                  src={
                    process.env.REACT_APP_API +
                    "/files/universities/" +
                    singleUniversity?.image
                  }
                />
              </div>{" "}
              <div className="uni_info_item">
                <span className="uni_info_item_title">Location:</span>
                <span className="uni_info_item_value">
                  {singleUniversity?.country}, {singleUniversity?.city}
                </span>
              </div>{" "}
              <div className="uni_info_item">
                <span className="uni_info_item_title">Deadline:</span>
                <span className="uni_info_item_value">
                  {singleUniversity?.application_deadline}
                </span>
              </div>{" "}
              <div className="uni_info_item">
                <span className="uni_info_item_title">Nb of Departments:</span>
                <span className="uni_info_item_value">
                  {singleUniversity?.summary?.Departments}
                </span>
              </div>{" "}
              <div className="uni_info_item">
                <span className="uni_info_item_title">Founded:</span>
                <span className="uni_info_item_value">
                  {singleUniversity?.summary?.Founded}
                </span>
              </div>
              <div className="uni_info_item">
                <span className="uni_info_item_title">Ranking:</span>
                <span className="uni_info_item_value">
                  {singleUniversity?.ranking}
                </span>
              </div>
              <div className="uni_info_item2"></div>
              <div className="uni_info_item" style={{ width: "330px" }}>
                <span className="uni_info_item_title">Pathway Provider:</span>
                <span className="uni_info_item_value">
                  {singleUniversity?.pathway_provider
                    ? singleUniversity?.pathway_provider?.provider
                    : "None"}
                </span>
              </div>{" "}
              <div className="uni_info_item3">
                <span className="uni_info_item_title">Languages:</span>
                <span className="uni_info_item_value">
                  {" "}
                  {singleUniversity?.languages?.map((lang, i) => {
                    if (i == singleUniversity?.languages?.length - 1) {
                      return lang;
                    } else {
                      return lang + ", ";
                    }
                  })}
                </span>
              </div>{" "}
              <div className="uni_info_item2"></div>
              <div className="uni_info_item3">
                <span className="uni_info_item_title">Semesters:</span>
                <span className="uni_info_item_value">
                  {singleUniversity?.semesters?.map((lang, i) => {
                    if (i == singleUniversity?.semesters?.length - 1) {
                      return (
                        lang.name +
                        " (" +
                        new Date(lang.start_date).toLocaleString("default", {
                          month: "long",
                        }) +
                        ")"
                      );
                    } else {
                      return lang.name + ", ";
                    }
                  })}
                </span>
              </div>
              <div className="uni_info_item2"></div>
              <div className="uni_info_item2">
                <span className="uni_info_item_title">Description:</span>
                <span className="uni_info_item_value">
                  {singleUniversity?.description}
                </span>
              </div>
            </div>
          </DialogContent>
          <DialogContent>
            <div className="uni_details_top">
              <Autocomplete
                style={{ width: "100%" }}
                options={singleUniversity.faculties}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Faculties"
                    variant="outlined"
                    fullWidth
                  />
                )}
                value={selectedFaculty}
                onChange={(e, value) => {
                  setSelectedFaculty(value);
                }}
              />
              <Autocomplete
                style={{ width: "100%" }}
                options={Majors}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Majors"
                    variant="outlined"
                    fullWidth
                  />
                )}
                value={selectedMajor}
                onChange={(e, value) => {
                  setselectedMajor(value);
                }}
              />
              <Autocomplete
                style={{ width: "100%" }}
                options={Degrees}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Degrees"
                    variant="outlined"
                    fullWidth
                  />
                )}
                value={selectedDegree}
                onChange={(e, value) => {
                  setSelectedDegree(value);
                }}
              />
            </div>
            <div>
              {Object.keys(MajorDegree).length > 0 && (
                <div className="uni_details_middle">
                  <div
                    className="about_details"
                    style={{ margin: "1rem 0 1rem 0" }}
                  >
                    <b>About this major: </b>
                    {MajorDegree.about}
                  </div>
                  <div className="about_bottom">
                    <div>
                      <div
                        style={{ paddingBottom: "0.5rem", fontSize: "1.2rem" }}
                      >
                        <b>Major Details</b>
                      </div>
                      <div>
                        <b>Duration: </b>
                        {MajorDegree.duration}
                      </div>
                      <div>
                        <b>Tuition: </b>${MajorDegree.tuition}/year
                      </div>
                      <div>
                        <b>Cost of living: </b>${MajorDegree.living_cost}/year
                      </div>
                      <div>
                        <b>Intakes: </b>{MajorDegree.intakes}/year
                      </div>
                    </div>
                    <div>
                      <div
                        style={{ paddingBottom: "0.5rem", fontSize: "1.2rem" }}
                      >
                        <b>Entry Requirments</b>
                      </div>
                      <ul>
                        {MajorDegree.entry_requirements.map((req, i) => (
                          <li key={i}>{req}</li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <div
                        style={{ paddingBottom: "0.5rem", fontSize: "1.2rem" }}
                      >
                        <b>Visa Requirments</b>
                      </div>
                      <ul>
                        {MajorDegree.visa_requirements.map((visa, i) => (
                          <li key={i}>{visa}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </DialogContent>
        </div>
      )}
    </Box>
  );
}

export default UniversityView;
