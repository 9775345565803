import { axiosReq } from "app/utils/utils";
import { LocationActions } from "./LocationReducer";

export const getAllCountries = () => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/location/countries`;
  dispatch(LocationActions.getCountriesRequest());
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success) dispatch(LocationActions.getCountriesSuccess(res.data));
    else
      dispatch(
        LocationActions.getCountriesFail(res.data.response.data.message)
      );
  });
};

export const getCitiesByContId = (id) => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/location/cities/${id}`;
  if (id) {
    dispatch(LocationActions.getCitiesRequest());
    dispatch(axiosReq("get", url, {}, {})).then((res) => {
      if (res.success) dispatch(LocationActions.getCitiesSuccess(res.data));
      else
        dispatch(LocationActions.getCitiesFail(res.data.response.data.message));
    });
  } else {
    return;
  }
};
