const { createSlice } = require("@reduxjs/toolkit");
const { countBy } = require("lodash");
const initialState = {
  countries: [],
  getCountriesLoading: false,
  getCountriesSuccess: false,
  getCountriesFail: "",
  ////////Add Countries////////
  addCountryLoading: false,
  addCountrySuccess: false,
  addCountryError: "",
  ////////Edit Countries////////
  editCountryLoading: false,
  editCountrySuccess: false,
  editCountryError: "",
  ////////Edit City////////
  editCityLoading: false,
  editCitySuccess: false,
  editCityError: false,

  ////////Add City////////
  addCityLoading: false,
  addCitySuccess: false,
  addCityError: false,

  ////////Delete City////////
  deleteCityLoading: false,
  deleteCitySuccess: false,
  deleteCityError: "",

  ////////Delete Country////////
  deleteCountryLoading: false,
  deleteCountrySuccess: false,
  deleteCountryError: "",
};

const LocationsSlice = createSlice({
  name: "Locations",
  initialState,
  reducers: {
    getCountriesRequest(state) {
      state.getCountriesLoading = true;
    },
    getCountriesSuccess(state, action) {
      state.countries = action.payload;
      state.getCountriesLoading = false;
      state.getCountriesSuccess = true;
      state.getCountriesFail = "";
    },
    getCountriesFail(state, action) {
      state.countries = [];
      state.getCountriesLoading = false;
      state.getCountriesSuccess = false;
      state.getCountriesFail = action.payload;
    },
    ////////Add Country////////
    addCountryRequest(state) {
      state.addCountryLoading = true;
    },
    addCountrySuccess(state) {
      state.addCountryLoading = false;
      state.addCountrySuccess = true;
      state.addCountryError = "";
    },
    addCountryError(state, action) {
      state.addCountryLoading = false;
      state.addCountrySuccess = false;
      state.addCountryError = action.payload;
    },
    ////////Edit Country////////
    editCountryRequest(state) {
      state.editCountryLoading = false;
    },
    editCountrySuccess(state, action) {
      state.editCountryLoading = false;
      state.editCountrySuccess = true;
      state.editCountryError = "";
    },
    editCountryError(state, action) {
      state.editCountryLoading = false;
      state.editCountrySuccess = false;
      state.editCountryError = action.payload;
    },
    ////////Edit City////////
    editCityRequest(state) {
      state.editCityLoading = false;
    },
    editCitySuccess(state, action) {
      state.editCityLoading = false;
      state.editCitySuccess = true;
      state.editCityError = "";
    },
    editCityError(state, action) {
      state.editCityLoading = false;
      state.editCitySuccess = false;
      state.editCityError = action.payload;
    },

    ////////Add City////////
    addCityRequest(state) {
      state.addCityLoading = true;
      state.addCityError = "";
      state.addCitySuccess = false;
    },
    addCitySuccess(state, action) {
      state.addCityLoading = false;
      state.addCitySuccess = true;
      state.addCityError = "";
    },
    addCityError(state, action) {
      state.addCityLoading = false;
      state.addCitySuccess = false;
      state.addCityError = action.payload;
    },

    ////////Delete City////////
    // deleteCityLoading:false,
    // deleteCitySuccess:false,
    // delteCityError:""
    deleteCityRequest(state) {
      state.deleteCityLoading = true;
    },
    deleteCitySuccess(state) {
      state.deleteCityLoading = false;
      state.deleteCitySuccess = true;
      state.deleteCityError = "";
    },
    deleteCityError(state, action) {
      state.deleteCityLoading = false;
      state.deleteCitySuccess = false;
      state.deleteCityError = action.payload;
    },
    ////////Delete Country////////
    // deleteCountryLoading:false,
    // deleteCountrySuccess:false,
    // deleteCountryError:""
    deleteCountryRequest(state) {
      state.deleteCountryLoading = true;
    },
    deleteCountrySuccess(state) {
      state.deleteCountryLoading = false;
      state.deleteCountrySuccess = true;
      state.deleteCountryError = "";
    },
    deleteCountryError(state, action) {
      state.deleteCountryLoading = false;
      state.deleteCountrySuccess = false;
      state.deleteCountryError = action.payload;
    },
  },
});
export const LocationsActions = LocationsSlice.actions;
export default LocationsSlice;
