import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, Icon, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogActions from "@mui/material/DialogActions";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import DropDown from "Components/DropDown/DropDown";
import { deleteOffice } from "Redux/Offices/OfficesActions";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const DialogTitleRoot = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
  "& .closeButton": {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogTitle = (props) => {
  const { children, onClose } = props;
  return (
    <DialogTitleRoot disableTypography>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className="closeButton"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitleRoot>
  );
};

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  "&.root": { padding: theme.spacing(2) },
}));

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  "&.root": { margin: 0, padding: theme.spacing(1) },
}));

function DeleteOfficePopup({ office }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const [selectedOffice, setSelectedOffice] = useState(null);

  const { allOffices, deleteOfficeError } = useSelector(
    (state) => state.officesReducer
  );

  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteOffice(handleClose, selectedOffice, office.id));
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <Icon>delete</Icon>
      </IconButton>

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth="sm"
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Delete Office
        </DialogTitle>

        <DialogContent dividers>
          <Typography gutterBottom>
            <p style={{ fontSize: "1.2rem" }}>
            Before deleting this office, reassign the counselors to another office of your choice.
              <Autocomplete
                options={allOffices.filter((o) => o.id !== office.id)}
                style={{ width: "100%", paddingTop: "1rem" }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Office"
                    variant="outlined"
                    fullWidth
                  />
                )}
                onChange={(e, value) => setSelectedOffice(value)}
                value={selectedOffice}
              />
            </p>
          </Typography>
          {deleteOfficeError && (
            <Typography gutterBottom color={"error"}>
              {deleteOfficeError}
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            onClick={handleDelete}
            color="error"
            disabled={!selectedOffice}
          >
            Delete
          </Button>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeleteOfficePopup;
