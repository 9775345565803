import { axiosReq } from "app/utils/utils";
import { DegreeActions } from "./DegreesReducer";

export const get_Degrees = () => async (dispatch) => {
  dispatch(DegreeActions.GetDegreesRequest());
  dispatch(axiosReq("get", process.env.REACT_APP_API + "/degree", {}, {})).then(
    (res) => {
      if (res.success) dispatch(DegreeActions.GetDegreesSuccess(res.data));
      else
        dispatch(DegreeActions.GetDegreesFail(res.data.response.data.message));
    }
  );
};

export const addDegree =
  (degree_name, documents, handleClose) => async (dispatch) => {
    dispatch(DegreeActions.addDegreeRequeat());
    dispatch(
      axiosReq(
        "post",
        process.env.REACT_APP_API + "/degree",
        {},
        {
          name: degree_name,
          documents: documents,
        }
      )
    ).then((res) => {
      if (res.success) {
        dispatch(DegreeActions.addDegreeSuccess());
        handleClose();
        dispatch(get_Degrees());
      } else
        dispatch(DegreeActions.addDegreeFail(res.data.response.data.message));
    });
  };

export const updateDegree =
  (degree_id, degree_name, documents, handleClose) => async (dispatch) => {
    dispatch(DegreeActions.updateDegreeRequest());
    dispatch(
      axiosReq(
        "patch",
        process.env.REACT_APP_API + "/degree/" + degree_id,
        {},
        {
          name: degree_name,
          documents: documents,
        }
      )
    ).then((res) => {
      if (res.success) {
        dispatch(DegreeActions.updateDegreeSuccess());
        handleClose();
        dispatch(get_Degrees());
      } else
        dispatch(
          DegreeActions.updateDegreeFail(res.data.response.data.message)
        );
    });
  };
