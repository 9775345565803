const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  allOffices: [],
  getAllOfficesLoading: false,
  getAllOfficesError: "",

  addOfficeLoading: false,
  addOfficeError: "",
  addOfficeSuccess: false,

  editOfficeLoading: false,
  editOfficeError: "",
  editOfficeSuccess: false,

  deleteOfficeLoading: false,
  deleteOfficeError: "",
  deleteOfficeSuccess: false,
};

const OfficesSlice = createSlice({
  name: "Offices",
  initialState,
  reducers: {
    fetchAllOfficesRequest(state) {
      state.getAllOfficesLoading = true;
      state.getAllOfficesError = "";
      state.allOffices = [];
    },
    fetchAllOfficesSuccess(state, action) {
      state.getAllOfficesLoading = false;
      state.getAllOfficesError = "";
      state.allOffices = action.payload;
    },
    fetchAllOfficesFail(state, action) {
      state.getAllOfficesLoading = false;
      state.getAllOfficesError = action.payload;
      state.allOffices = [];
    },

    addOfficeRequest(state) {
      state.addOfficeLoading = true;
      state.addOfficeError = "";
      state.addOfficeSuccess = false;
    },
    addOfficeSuccess(state) {
      state.addOfficeLoading = false;
      state.addOfficeError = "";
      state.addOfficeSuccess = true;
    },
    addOfficeFail(state, action) {
      state.addOfficeLoading = false;
      state.addOfficeError = action.payload;
      state.addOfficeSuccess = false;
    },

    editOfficeRequest(state) {
      state.editOfficeLoading = true;
      state.editOfficeError = "";
      state.editOfficeSuccess = false;
    },
    editOfficeSuccess(state) {
      state.editOfficeLoading = false;
      state.editOfficeError = "";
      state.editOfficeSuccess = true;
    },
    editOfficeFail(state, action) {
      state.editOfficeLoading = false;
      state.editOfficeError = action.payload;
      state.editOfficeSuccess = false;
    },

    deleteOfficeRequest(state) {
      state.deleteOfficeLoading = true;
      state.deleteOfficeError = "";
      state.deleteOfficeSuccess = false;
    },
    deleteOfficeSuccess(state) {
      state.deleteOfficeLoading = false;
      state.deleteOfficeError = "";
      state.deleteOfficeSuccess = true;
    },
    deleteOfficeFail(state, action) {
      state.deleteOfficeLoading = false;
      state.deleteOfficeError = action.payload;
      state.deleteOfficeSuccess = false;
    },
  },
});

export const OfficesActions = OfficesSlice.actions;
export default OfficesSlice;
