import React, { useEffect } from "react";
import "./Offices.css";
import AddOfficePopup from "./AddOfficePopup";
import { useDispatch, useSelector } from "react-redux";
import { getAllOffices } from "Redux/Offices/OfficesActions";
import OfficesTable from "./OfficesTable";
import { logoutUser } from "../../Redux/User/UserActions";
import { useNavigate } from "react-router-dom";

function Offices() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOffices());
  }, []);
  const role = useSelector((state) => state.User?.user?.role);
  const navigate = useNavigate();
  useEffect(() => {
    if (role && role !== "admin") {
      dispatch(logoutUser(navigate));
    }
  }, [role]);
  return (
    <div className="offices_main">
      <div className="offices_top">
        <h1 className="offices_top_title">Offices Table</h1>
        <AddOfficePopup />
      </div>
      <OfficesTable />
    </div>
  );
}

export default Offices;
