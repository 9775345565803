const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  allProviders: [],
  getAllProvidersLoading: false,
  getAllProvidersFail: false,

  editProviderLoading: false,
  editProviderFail: "",
  editProviderSuccess: false,

  addProviderLoading: false,
  addProviderFail: "",
  addProviderSuccess: false,
};

const ProvidersSlice = createSlice({
  name: "Providers",
  initialState,
  reducers: {
    fetchAllProvidersRequest(state) {
      state.getAllProvidersLoading = true;
      state.getAllProvidersFail = false;
      state.allProviders = [];
    },
    fetchAllProvidersSuccess(state, action) {
      state.getAllProvidersLoading = false;
      state.getAllProvidersFail = false;
      state.allProviders = action.payload;
    },
    fetchAllProvidersFail(state) {
      state.getAllProvidersLoading = false;
      state.getAllProvidersFail = true;
      state.allProviders = [];
    },

    editProviderRequest(state) {
      state.editProviderLoading = true;
      state.editProviderFail = "";
      state.editProviderSuccess = false;
    },
    editProviderSuccess(state) {
      state.editProviderLoading = false;
      state.editProviderFail = "";
      state.editProviderSuccess = true;
    },
    editProviderFail(state, action) {
      state.editProviderLoading = false;
      state.editProviderFail = action.payload;
      state.editProviderSuccess = false;
    },

    addProviderRequest(state) {
      state.addProviderLoading = true;
      state.addProviderFail = "";
      state.addProviderSuccess = false;
    },
    addProviderSuccess(state) {
      state.addProviderLoading = false;
      state.addProviderFail = "";
      state.addProviderSuccess = true;
    },
    addProviderFail(state, action) {
      state.addProviderLoading = false;
      state.addProviderFail = action.payload;
      state.addProviderSuccess = false;
    },
  },
});

export const ProvidersActions = ProvidersSlice.actions;
export default ProvidersSlice;
