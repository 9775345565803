/** @format */

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  StudentsList: [],
  Student: [],
  getstudentLoading: false,
  getstudentError: "",
  getsinglestudentLoading: false,
  getsinglestudentError: "",
};

const StudentsSlice = createSlice({
  name: "Students",
  initialState,
  reducers: {
    GetStudentsRequest(state) {
      state.getstudentLoading = true;
      state.getstudentError = "";
      state.StudentsList = [];
    },
    GetStudentsSuccess(state, action) {
      state.getstudentLoading = false;
      state.getstudentError = "";
      state.StudentsList = action.payload;
    },
    GetStudentsFail(state, action) {
      state.getstudentLoading = false;
      state.getstudentError = action.payload;
      state.StudentsList = [];
    },
    getsinglestudentRequest(state) {
      state.getsinglestudentLoading = true;
      state.getstudentError = "";
      state.Student = [];
    },
    getsinglestudentSuccess(state, action) {
      state.getsinglestudentLoading = false;
      state.getsinglestudentError = "";
      state.Student = action.payload;
    },
    getsinglestudentFail(state, action) {
      state.getsinglestudentLoading = false;
      state.getsinglestudentError = action.payload;
      state.Student = [];
    },
  },
});

export const StudentsActions = StudentsSlice.actions;
export default StudentsSlice;
