import React, { useEffect, useState } from "react";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { get_Degrees } from "Redux/Degrees/DegreesActions";
import { MatxLoading } from "app/components";
import ViewDegreePopup from "./ViewDegreePopup";
import { Edit } from "@mui/icons-material";
import EditDegreePopup from "./EditDegreePopup";

function DegreeTable() {
  const StyledTable = styled(Table)(({ theme }) => ({
    whiteSpace: "pre",
    "& thead": {
      "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
      "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
  }));

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(get_Degrees());
  }, []);

  const { degrees, getDegreesLoading } = useSelector(
    (state) => state.degreeReducer
  );

  return (
    <div>
      {!getDegreesLoading ? (
        <Box width="100%" overflow="auto">
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell align="left">Name</TableCell>
                <TableCell align="center">Number of Documents Needed</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {degrees.map((degree, index) => (
                <TableRow key={index}>
                  <TableCell align="left">{degree.name}</TableCell>
                  <TableCell align="center">
                    {degree.documents.length}
                  </TableCell>
                  <TableCell align="right">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                        justifyContent: "flex-end",
                      }}
                    >
                      <EditDegreePopup index={index} />
                      <ViewDegreePopup index={index} />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </Box>
      ) : (
        <MatxLoading />
      )}
    </div>
  );
}

export default DegreeTable;
