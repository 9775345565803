import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, FormControlLabel, Icon, Switch } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogActions from "@mui/material/DialogActions";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { MatxLoading } from "app/components";
import {
  getAllCountries,
  getCitiesByContId,
} from "Redux/Location/LocationActions";
import { set } from "lodash";
import {
  addProvider,
  editProviderById,
} from "Redux/Providers/ProvidersActions";

const DialogTitleRoot = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
  "& .closeButton": {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogTitle = (props) => {
  const { children, onClose } = props;
  return (
    <DialogTitleRoot disableTypography>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className="closeButton"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitleRoot>
  );
};

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  "&.root": { padding: theme.spacing(2) },
}));

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  "&.root": { margin: 0, padding: theme.spacing(1) },
}));

function AddProvidersPopup() {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const { countries, cities } = useSelector((state) => state.LocationReducer);
  const { editProviderFail } = useSelector((state) => state.providersReducer);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [newMedecine, setNewMedecine] = useState(false);
  const [newOnline, setNewOnline] = useState(false);
  const [newName, setNewName] = useState("");
  const [newInstitution, setNewInstitution] = useState("");
  const [newCommision, setNewCommision] = useState("");
  const [newLink, setNewLink] = useState("");
  const [newApplicationProcedure, setNewApplicationProcedure] = useState("");
  const [newContact, setNewContact] = useState("");

  useEffect(() => {
    dispatch(getAllCountries());
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      dispatch(getCitiesByContId(selectedCountry.id));
    }
  }, [selectedCountry]);

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add a Provider
      </Button>

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Add a Provider
        </DialogTitle>

        <DialogContent dividers>
          <div
            style={{
              paddingBottom: "0.5rem",
              width: "30rem",
              display: "flex",
              justifyContent: "space-between",
              gap: "0.5rem",
            }}
          >
            <TextField
              id="outlined-basic"
              label={"Name"}
              variant="outlined"
              style={{ width: "100%" }}
              value={newName}
              onChange={(e) => {
                setNewName(e.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              label={"Institution"}
              variant="outlined"
              style={{ width: "100%" }}
              value={newInstitution}
              onChange={(e) => {
                setNewInstitution(e.target.value);
              }}
            />
          </div>
          <div
            style={{
              paddingBottom: "0.5rem",
              width: "30rem",
              display: "flex",
              justifyContent: "space-between",
              gap: "0.5rem",
            }}
          >
            <Autocomplete
              options={countries}
              style={{ width: "100%" }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="County"
                  variant="outlined"
                  fullWidth
                />
              )}
              value={selectedCountry != "" ? selectedCountry : null}
              onChange={(event, newValue) => {
                setSelectedCountry(newValue);
                setSelectedCity(null);
              }}
            />
            <Autocomplete
              options={cities}
              style={{ width: "100%" }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="City"
                  variant="outlined"
                  fullWidth
                  value={selectedCity}
                  onChange={(e) => {
                    setSelectedCity(e.target.value);
                  }}
                />
              )}
              value={selectedCity != "" ? selectedCity : null}
              onChange={(event, newValue) => {
                setSelectedCity(newValue);
              }}
            />
          </div>
          <div
            style={{
              paddingBottom: "0.5rem",
              width: "30rem",
              display: "flex",
              justifyContent: "space-between",
              gap: "0.5rem",
            }}
          >
            <TextField
              id="outlined-basic"
              label={"Application Procedure"}
              variant="outlined"
              style={{ width: "100%" }}
              value={newApplicationProcedure}
              onChange={(e) => {
                setNewApplicationProcedure(e.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              label={"Contact"}
              variant="outlined"
              style={{ width: "100%" }}
              value={newContact}
              onChange={(e) => {
                setNewContact(e.target.value);
              }}
            />
          </div>
          <div
            style={{
              paddingBottom: "0.5rem",
              width: "30rem",
              display: "flex",
              justifyContent: "space-between",
              gap: "0.5rem",
            }}
          >
            <TextField
              id="outlined-basic"
              label={"Link"}
              variant="outlined"
              style={{ width: "100%" }}
              value={newLink}
              onChange={(e) => {
                setNewLink(e.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              label={"Commission"}
              variant="outlined"
              style={{ width: "100%" }}
              value={newCommision}
              onChange={(e) => {
                setNewCommision(e.target.value);
              }}
            />
          </div>
          <div
            style={{
              paddingBottom: "0.5rem",
              width: "25rem",
              margin: "auto",
              display: "flex",
              justifyContent: "space-between",
              gap: "0.5rem",
            }}
          >
            <FormControlLabel
              value="start"
              control={
                <Switch
                  value={newMedecine}
                  checked={newMedecine}
                  onChange={() => setNewMedecine(!newMedecine)}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              }
              label="Medicine"
              labelPlacement="start"
            />
            <FormControlLabel
              value="start"
              control={
                <Switch
                  value={newOnline}
                  checked={newOnline}
                  onChange={() => setNewOnline(!newOnline)}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              }
              label="Online Program"
              labelPlacement="start"
            />
          </div>
          {editProviderFail && (
            <Typography gutterBottom style={{ color: "red" }}>
              {editProviderFail}
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(
                addProvider(handleClose, {
                  ...(newName && { provider: newName }),
                  ...(newInstitution && {
                    institution: newInstitution,
                  }),
                  ...(selectedCountry && {
                    country: selectedCountry.name,
                  }),
                  ...(selectedCity && {
                    city: selectedCity.name,
                  }),
                  ...(newApplicationProcedure && {
                    application_procedure: newApplicationProcedure,
                  }),
                  ...(newContact && { contact: newContact }),
                  ...(newLink && {
                    search_tool: newLink,
                  }),
                  ...(newCommision && {
                    commission: newCommision,
                  }),
                  ...{
                    medecine: newMedecine,
                  },
                  ...{
                    online_program: newOnline,
                  },
                })
              );
            }}
            disabled={
              !newName ||
              !selectedCountry ||
              !selectedCity ||
              !newInstitution ||
              !newApplicationProcedure ||
              !newContact ||
              !newLink ||
              !newCommision
                ? true
                : false
            }
          >
            Save changes
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleClose();
            }}
            color="warning"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddProvidersPopup;
