/** @format */

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  //get all major categories
  getallmajorcategoriesLoading: false,
  getallmajorcategoriesError: "",
  getallmajorcategoriesSuccess: false,
  allmajorcategories: [],

  //edit major category
  editmajorcategoryLoading: false,
  editmajorcategoryError: "",
  editmajorcategorySuccess: false,

  //add major category
  addmajorcategoryLoading: false,
  addmajorcategoryError: "",
  addmajorcategorySuccess: false,
};

const MajorCategoriesSlice = createSlice({
  name: "Major Categories",
  initialState,
  reducers: {
    //get all major categories
    getallmajorcategoriesRequest(state) {
      state.getallmajorcategoriesLoading = true;
      state.getallmajorcategoriesError = "";
      state.allmajorcategories = {};
      state.getallmajorcategoriesSuccess = false;
    },
    getallmajorcategoriesSuccess(state, action) {
      state.getallmajorcategoriesLoading = false;
      state.getallmajorcategoriesError = "";
      state.allmajorcategories = action.payload;
      state.getallmajorcategoriesSuccess = true;
    },
    getallmajorcategoriesFail(state, action) {
      state.getallmajorcategoriesLoading = false;
      state.getallmajorcategoriesError = action.payload;
      state.allmajorcategories = {};
      state.getallmajorcategoriesSuccess = false;
    },

    //edit major category
    editmajorcategoryRequest(state) {
      state.editmajorcategoryLoading = true;
      state.editmajorcategoryError = "";
      state.editmajorcategorySuccess = false;
    },
    editmajorcategorySuccess(state, action) {
      state.editmajorcategoryLoading = false;
      state.editmajorcategoryError = "";
      state.editmajorcategorySuccess = true;
    },
    editmajorcategoryFail(state, action) {
      state.editmajorcategoryLoading = false;
      state.editmajorcategoryError = action.payload;
      state.editmajorcategorySuccess = false;
    },

    //add major category
    addmajorcategoryRequest(state) {
      state.addmajorcategoryLoading = true;
      state.addmajorcategoryError = "";
      state.addmajorcategorySuccess = false;
    },
    addmajorcategorySuccess(state, action) {
      state.addmajorcategoryLoading = false;
      state.addmajorcategoryError = "";
      state.addmajorcategorySuccess = true;
    },

    addmajorcategoryFail(state, action) {
      state.addmajorcategoryLoading = false;
      state.addmajorcategoryError = action.payload;
      state.addmajorcategorySuccess = false;
    },
  },
});

export const MajorCategoriesActions = MajorCategoriesSlice.actions;
export default MajorCategoriesSlice;
