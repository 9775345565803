import {
  Autocomplete,
  Button,
  Icon,
  IconButton,
  TextField,
  useIsFocusVisible,
} from "@mui/material";
import {
  addFaculty,
  addMajor,
  editFaculty,
  editMajor,
  getMajors,
} from "Redux/Universities/UniversitiesActions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PopUpDel from "Components/DeletePop/PopUpDel";
import { getAllMajorGroups } from "Redux/MajorCategories/MajorCategoriesActions";

function EditMajor({ oldFaculties }) {
  const {
    Majors,
    addMajorLoading,
    addMajorError,
    addMajorSuccess,
    editMajorLoading,
    editMajorError,
    editMajorSuccess,
  } = useSelector((state) => state.UniversitiesReducer);
  const { allmajorcategories } = useSelector((state) => state.MajorCategories);
  const dispatch = useDispatch();
  const [add, setAdd] = useState(false);
  const [error, setError] = useState("");
  const [edit, setEdit] = useState({});
  const { id } = useParams();
  const [deleteP, setDeleteP] = useState(false);
  const [selectedFac, setSelectedFac] = useState(null);
  const [newMaj, setNewMaj] = useState({});
  const [clicked, setClicked] = useState(false);
  const handleSave = (deg) => {
    if (!edit.name) {
      setError("Please fill all the fields");
    } else {
      setEdit({ step: -1 });
      setError("");
      dispatch(
        editMajor(
          deg.id,
          { name: edit.name, major_group: edit.catg.id },
          selectedFac.id
        )
      );
    }
  };

  const handleAdd = () => {
    if (!newMaj.name || !newMaj.catg) {
      setError("Please fill all the fields");
    } else {
      setError("");
      const maj = {
        name: newMaj.name,
        faculty: selectedFac.id,
        major_group: newMaj.catg.id,
      };
      setClicked(true);
      dispatch(addMajor(maj, selectedFac.id));
    }
  };

  const handleDelete = () => {
    setDeleteP(false);
  };

  useEffect(() => {
    if (selectedFac?.id) {
      dispatch(getMajors(selectedFac.id));
    }
  }, [selectedFac]);
  useEffect(() => {
    dispatch(getAllMajorGroups());
  }, []);

  useEffect(() => {
    if (addMajorError) {
      setError(addMajorError);
    } else if (editMajorError) {
      setError(editMajorError);
    }
  }, [addMajorError, editMajorError]);

  useEffect(() => {
    if (addMajorSuccess && clicked) {
      setAdd(false);
      setNewMaj({});
      setClicked(false);
    }
  }, [addMajorSuccess]);

  return (
    <div className="add_faculty_main_div">
      <div className="add_facutly_header">
        <h3>Edit Majors</h3>
      </div>

      <div className="add_major_body_div2">
        <div className="add_major_body_div">
          <Autocomplete
            style={{ width: "30%" }}
            options={oldFaculties}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose Faculty"
                variant="outlined"
                fullWidth
              />
            )}
            value={selectedFac}
            onChange={(e, value) => {
              setSelectedFac(value);
            }}
          />
          <Button
            variant="contained"
            onClick={() => {
              setAdd(true);
              setEdit({});
            }}
            disabled={selectedFac?.id ? false : true}
          >
            Add Major
          </Button>
        </div>
        {Majors?.map((deg, index) => {
          return (
            <ul>
              <li>
                <div className="add_major_body_div2_li_div">
                  <div style={{ minWidth: "8rem" }}>{deg.name}</div>
                  <IconButton>
                    <Icon
                      color="primary"
                      onClick={() => {
                        setAdd(false);
                        setEdit({
                          step: index,
                          name: deg.name,
                          catg: deg.major_group,
                        });
                      }}
                    >
                      edit
                    </Icon>
                  </IconButton>
                </div>
                {edit.step === index && (
                  <div className="add_major_body_div2_li_div">
                    <TextField
                      style={{ width: "30%" }}
                      label="Major Name"
                      value={edit.name}
                      onChange={(e) => {
                        setEdit({ ...edit, name: e.target.value });
                      }}
                    />
                    <Autocomplete
                      style={{ width: "30%" }}
                      options={allmajorcategories}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          label="Major Category"
                        />
                      )}
                      value={edit.catg}
                      onChange={(e, value) => {
                        setEdit({ ...edit, catg: value });
                      }}
                    />
                    {error && <div className="error_div">{error}</div>}

                    <Button
                      variant="contained"
                      color="primary"
                      disabled={editMajorLoading || addMajorLoading}
                      onClick={() => {
                        handleSave(deg);
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setEdit({ step: -1 });
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
              </li>
            </ul>
          );
        })}
      </div>
      {deleteP && (
        <PopUpDel setopen={setDeleteP} open={deleteP} func={handleDelete} />
      )}
      <div className="edit_faculty_body">
        {add && (
          <div className="add_major_body_div">
            <TextField
              style={{ width: "30%" }}
              label="Major Name"
              onChange={(e) => {
                setNewMaj({ ...newMaj, name: e.target.value });
              }}
            />
            <Autocomplete
              style={{ width: "30%" }}
              options={allmajorcategories}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Major Group"
                  variant="outlined"
                  fullWidth
                />
              )}
              value={newMaj.catg}
              onChange={(e, value) => {
                setNewMaj({ ...newMaj, catg: value });
              }}
            />
            {error && <div className="error_div">{error}</div>}

            <>
              {" "}
              <Button
                variant="contained"
                onClick={() => handleAdd()}
                disabled={editMajorLoading || addMajorLoading}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => setAdd(false)}
              >
                Cancel
              </Button>
            </>
          </div>
        )}
      </div>
    </div>
  );
}

export default EditMajor;
