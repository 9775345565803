import { Card, Grid, styled, useTheme } from "@mui/material";
import { Fragment } from "react";
import UniversitiesTable from "./UniversitiesTable";
const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
}));

const Universities = () => {

  return (
    <Fragment>
      <ContentBox className="universities_main">
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <UniversitiesTable />
        </Grid>
      </ContentBox>
    </Fragment>
  );
};

export default Universities;
