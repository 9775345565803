/** @format */

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  Counselors: [],
  getCounselorsLoading: false,
  getCounselorsError: "",
  addcounselorLoading: false,
  addcounselorSuccess: false,
  addcounselorError: "",
  Countries: [],
  getCountriesLoading: false,
  getCountriesError: "",
  getofficesLoading: false,

  getofficesError: "",
  Offices: [],
  editcounselorLoading: false,
  editcounselorSuccess: false,
  editcounselorError: "",
  deletecounselorLoading: false,
  deletecounselorError: "",
  deletecounselorSuccess: false,
};

const CounselorsSlice = createSlice({
  name: "Counselors",
  initialState,
  reducers: {
    GetCounselorsRequest(state) {
      state.getCounselorsLoading = true;
      state.getCounselorsError = "";
      state.Counselors = [];
    },
    GetCounselorsSuccess(state, action) {
      state.getCounselorsLoading = false;
      state.getCounselorsError = "";
      state.Counselors = action.payload;
    },
    GetCounselorsFail(state, action) {
      state.getCounselorsLoading = false;
      state.getCounselorsError = action.payload;
      state.Counselors = [];
    },
    AddCounselorRequest(state) {
      state.addcounselorLoading = true;
      state.addcounselorError = "";
      state.addcounselorSuccess = false;
    },
    AddCounselorSuccess(state, action) {
      state.addcounselorLoading = false;
      state.addcounselorError = "";
      state.addcounselorSuccess = true;
    },
    AddCounselorFail(state, action) {
      state.addcounselorLoading = false;
      state.addcounselorError = action.payload;
      state.addcounselorSuccess = false;
    },
    getcountriesRequest(state) {
      state.getCountriesLoading = true;
      state.getCountriesError = "";
    },
    getcountriesSuccess(state, action) {
      state.getCountriesLoading = false;
      state.getCountriesError = "";
      state.Countries = action.payload;
    },
    getcountriesFail(state, action) {
      state.getCountriesLoading = false;
      state.getCountriesError = action.payload;
      state.Countries = [];
    },
    getofficesRequest(state) {
      state.getofficesLoading = true;
      state.getofficesError = "";
    },
    getofficesSuccess(state, action) {
      state.getofficesLoading = false;
      state.getofficesError = "";
      state.Offices = action.payload;
    },
    getofficesFail(state, action) {
      state.getofficesLoading = false;
      state.getofficesError = action.payload;
      state.Offices = [];
    },
    editcounselorRequest(state) {
      state.editcounselorLoading = true;
      state.editcounselorError = "";
      state.editcounselorSuccess = false;
    },
    editcounselorSuccess(state, action) {
      state.editcounselorLoading = false;
      state.editcounselorError = "";
      state.editcounselorSuccess = true;
    },
    editcounselorFail(state, action) {
      state.editcounselorLoading = false;
      state.editcounselorError = action.payload;
      state.editcounselorSuccess = false;
    },
    deletecounselorRequest(state) {
      state.deletecounselorLoading = true;
      state.deletecounselorError = "";
      state.deletecounselorSuccess = false;
    },
    deletecounselorSuccess(state, action) {
      state.deletecounselorLoading = false;
      state.deletecounselorError = "";
      state.deletecounselorSuccess = true;
    },
    deletecounselorFail(state, action) {
      state.deletecounselorLoading = false;
      state.deletecounselorError = action.payload;
      state.deletecounselorSuccess = false;
    },
  },
});

export const CounselorsActions = CounselorsSlice.actions;
export default CounselorsSlice;
