/** @format */

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  //get all universities
  getuniversitiesLoading: false,
  getuniversitiesError: "",
  getuniversitiesSuccess: false,
  universities: [],

  //edit university
  edituniversityLoading: false,
  edituniversityError: "",
  edituniversitySuccess: false,

  //get single university
  getuniversityLoading: false,
  getuniversityError: "",
  getuniversitySuccess: false,
  singleUniversity: {},

  //add university
  adduniversityLoading: false,
  adduniversityError: "",
  adduniversitySuccess: false,
  newUniversity: {},
  //get majors based on faculty
  getMajorsLoading: false,
  getMajorsError: "",
  Majors: [],

  //get degree based on major
  getDegreesLoading: false,
  getDegreesError: "",
  Degrees: [],

  //get majorDegree Data
  getMajorDegreeLoading: false,
  getMajorDegreeError: "",
  MajorDegree: {},

  //add semesters
  addSemesterLoading: false,
  addSemesterError: "",
  addSemesterSuccess: false,

  //add faculty
  addFacultyLoading: false,
  addFacultyError: "",
  addFacultySuccess: false,
  newFaculty: {},

  //add major
  addMajorLoading: false,
  addMajorError: "",
  addMajorSuccess: false,
  newMajor: {},

  //add majorDegree
  addMajorDegreeLoading: false,
  addMajorDegreeError: "",
  addMajorDegreeSuccess: false,

  //edit semesters
  editSemesterLoading: false,
  editSemesterError: "",
  editSemesterSuccess: false,

  //delete semesters
  deleteSemesterLoading: false,
  deleteSemesterError: "",
  deleteSemesterSuccess: false,

  //edit faculty
  editFacultyLoading: false,
  editFacultyError: "",
  editFacultySuccess: false,


  //edit major
  editMajorLoading: false,
  editMajorError: "",
  editMajorSuccess: false,

  // edit major degree
  editMajorDegreeLoading: false,
  editMajorDegreeError: "",
  editMajorDegreeSuccess: false,

};

const UniversitiesSlice = createSlice({
  name: "Universities",
  initialState,
  reducers: {
    //get all universities
    getuniversitiesRequest(state) {
      state.getuniversitiesLoading = true;
      state.getuniversitiesError = "";
      state.getuniversitiesSuccess = false;
      state.universities = [];
    },
    getuniversitiesSuccess(state, action) {
      state.getuniversitiesLoading = false;
      state.getuniversitiesError = "";
      state.getuniversitiesSuccess = true;
      state.universities = action.payload;
    },
    getuniversitiesFail(state, action) {
      state.getuniversitiesLoading = false;
      state.getuniversitiesError = action.payload;
      state.getuniversitiesSuccess = false;
      state.universities = [];
    },

    //edit university
    edituniversityRequest(state) {
      state.edituniversityLoading = true;
      state.edituniversityError = "";
      state.edituniversitySuccess = false;
    },
    edituniversitySuccess(state, action) {
      state.edituniversityLoading = false;
      state.edituniversityError = "";
      state.edituniversitySuccess = true;
    },
    edituniversityFail(state, action) {
      state.edituniversityLoading = false;
      state.edituniversityError = action.payload;
      state.edituniversitySuccess = false;
    },

    // get single university
    getSingleUniversityRequest(state) {
      state.getuniversityLoading = true;
      state.getuniversityError = "";
      state.getuniversitySuccess = false;
      state.singleUniversity = {};
    },
    getSingleUniversitySuccess(state, action) {
      state.getuniversityLoading = false;
      state.getuniversityError = "";
      state.getuniversitySuccess = true;
      state.singleUniversity = action.payload;
    },
    getSingleUniversityFail(state, action) {
      state.getuniversityLoading = false;
      state.getuniversityError = action.payload;
      state.getuniversitySuccess = false;
      state.singleUniversity = {};
    },

    //add university
    adduniversityRequest(state) {
      state.adduniversityLoading = true;
      state.adduniversityError = "";
      state.adduniversitySuccess = false;
      state.newUniversity = {};
    },
    adduniversitySuccess(state, action) {
      state.adduniversityLoading = false;
      state.adduniversityError = "";
      state.newUniversity = action.payload;

      state.adduniversitySuccess = true;
    },
    adduniversityFail(state, action) {
      state.adduniversityLoading = false;
      state.adduniversityError = action.payload;
      state.adduniversitySuccess = false;

      state.newUniversity = {};
    },

    //get majors based on faculty
    getMajorsRequest(state) {
      state.getMajorsLoading = true;
      state.getMajorsError = "";
      state.Majors = [];
    },
    getMajorsSuccess(state, action) {
      state.getMajorsLoading = false;
      state.getMajorsError = "";
      state.Majors = action.payload;
    },
    getMajorsFail(state, action) {
      state.getMajorsLoading = false;
      state.getMajorsError = action.payload;
      state.Majors = [];
    },

    //get degree based on major
    getDegreesRequest(state) {
      state.getDegreesLoading = true;
      state.getDegreesError = "";
      state.Degrees = [];
    },
    getDegreesSuccess(state, action) {
      state.getDegreesLoading = false;
      state.getDegreesError = "";
      state.Degrees = action.payload;
    },
    getDegreesFail(state, action) {
      state.getDegreesLoading = false;
      state.getDegreesError = action.payload;
      state.Degrees = [];
    },

    //get majorDegree Data
    getMajorDegreeRequest(state) {
      state.getMajorDegreeLoading = true;
      state.getMajorDegreeError = "";
      state.MajorDegree = {};
    },
    getMajorDegreeSuccess(state, action) {
      state.getMajorDegreeLoading = false;
      state.getMajorDegreeError = "";
      state.MajorDegree = action.payload;
    },
    getMajorDegreeFail(state, action) {
      state.getMajorDegreeLoading = false;
      state.getMajorDegreeError = action.payload;
      state.MajorDegree = {};
    },

    //add semesters
    addSemesterRequest(state) {
      state.addSemesterLoading = true;
      state.addSemesterError = "";
      state.addSemesterSuccess = false;
    },
    addSemesterSuccess(state, action) {
      state.addSemesterLoading = false;
      state.addSemesterError = "";
      state.addSemesterSuccess = true;
    },
    addSemesterFail(state, action) {
      state.addSemesterLoading = false;
      state.addSemesterError = action.payload;
      state.addSemesterSuccess = false;
    },

    //add faculty
    addFacultyRequest(state) {
      state.addFacultyLoading = true;
      state.addFacultyError = "";
      state.addFacultySuccess = false;
      state.newFaculty = {};
    },
    addFacultySuccess(state, action) {
      state.addFacultyLoading = false;
      state.addFacultyError = "";
      state.newFaculty = action.payload;
      state.addFacultySuccess = true;
    },
    addFacultyFail(state, action) {
      state.addFacultyLoading = false;
      state.addFacultyError = action.payload;
      state.addFacultySuccess = false;
      state.newFaculty = {};
    },

    //add major
    addMajorRequest(state) {
      state.addMajorLoading = true;
      state.addMajorError = "";
      state.addMajorSuccess = false;
      state.newMajor = {};
    },
    addMajorSuccess(state, action) {
      state.addMajorLoading = false;
      state.addMajorError = "";
      state.addMajorSuccess = true;
      state.newMajor = action.payload;
    },
    addMajorFail(state, action) {
      state.addMajorLoading = false;
      state.addMajorError = action.payload;
      state.addMajorSuccess = false;
      state.newMajor = {};
    },

    //add majorDegree
    addMajorDegreeRequest(state) {
      state.addMajorDegreeLoading = true;
      state.addMajorDegreeError = "";
      state.addMajorDegreeSuccess = false;
    },
    addMajorDegreeSuccess(state, action) {
      state.addMajorDegreeLoading = false;
      state.addMajorDegreeError = "";
      state.addMajorDegreeSuccess = true;
    },
    addMajorDegreeFail(state, action) {
      state.addMajorDegreeLoading = false;
      state.addMajorDegreeError = action.payload;
      state.addMajorDegreeSuccess = false;
    },

    //edit semesters
    editSemesterRequest(state) {
      state.editSemesterLoading = true;
      state.editSemesterError = "";
      state.editSemesterSuccess = false;
    },
    editSemesterSuccess(state, action) {
      state.editSemesterLoading = false;
      state.editSemesterError = "";
      state.editSemesterSuccess = true;
    },
    editSemesterFail(state, action) {
      state.editSemesterLoading = false;
      state.editSemesterError = action.payload;
      state.editSemesterSuccess = false;
    },

    //delete semesters
    deleteSemesterRequest(state) {
      state.deleteSemesterLoading = true;
      state.deleteSemesterError = "";
      state.deleteSemesterSuccess = false;
    },
    deleteSemesterSuccess(state, action) {
      state.deleteSemesterLoading = false;
      state.deleteSemesterError = "";
      state.deleteSemesterSuccess = true;
    },
    deleteSemesterFail(state, action) {
      state.deleteSemesterLoading = false;
      state.deleteSemesterError = action.payload;
      state.deleteSemesterSuccess = false;
    },

    //edit faculty
    editFacultyRequest(state) {
      state.editFacultyLoading = true;
      state.editFacultyError = "";
      state.editFacultySuccess = false;
    },
    editFacultySuccess(state, action) {
      state.editFacultyLoading = false;
      state.editFacultyError = "";
      state.editFacultySuccess = true;
    } ,
    editFacultyFail(state, action) {

      state.editFacultyLoading = false;
      state.editFacultyError = action.payload;
      state.editFacultySuccess = false;
    },

    //edit major
    editMajorRequest(state) {
      state.editMajorLoading = true;
      state.editMajorError = "";
      state.editMajorSuccess = false;
    },
    editMajorSuccess(state, action) {
      state.editMajorLoading = false;
      state.editMajorError = "";
      state.editMajorSuccess = true;
    },
    editMajorFail(state, action) {
      state.editMajorLoading = false;
      state.editMajorError = action.payload;
      state.editMajorSuccess = false;
    },

    //edit major degree
    editMajorDegreeRequest(state) {
      state.editMajorDegreeLoading = true;
      state.editMajorDegreeError = "";
      state.editMajorDegreeSuccess = false;
    },
    editMajorDegreeSuccess(state, action) {
      state.editMajorDegreeLoading = false;
      state.editMajorDegreeError = "";
      state.editMajorDegreeSuccess = true;
    },
    editMajorDegreeFail(state, action) {
      state.editMajorDegreeLoading = false;
      state.editMajorDegreeError = action.payload;
      state.editMajorDegreeSuccess = false;
    }
  },
});
export const UniversitiesActions = UniversitiesSlice.actions;
export default UniversitiesSlice;
