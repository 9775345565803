/** @format */

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  degrees: [],
  getDegreesLoading: false,
  getDegreesError: "",

  addDegreeLoading: false,
  addDegreeError: "",

  updateDegreeLoading: false,
  updateDegreeError: "",
};

const DegreeSlice = createSlice({
  name: "Degree",
  initialState,
  reducers: {
    GetDegreesRequest(state) {
      state.getDegreesLoading = true;
      state.getDegreesError = "";
      state.degrees = [];
    },
    GetDegreesSuccess(state, action) {
      state.getDegreesLoading = false;
      state.getDegreesError = "";
      state.degrees = action.payload;
    },
    GetDegreesFail(state, action) {
      state.getDegreesLoading = false;
      state.getDegreesError = action.payload;
      state.degrees = [];
    },

    addDegreeRequeat(state) {
      state.addDegreeLoading = true;
      state.addDegreeError = "";
    },
    addDegreeSuccess(state, action) {
      state.addDegreeLoading = false;
      state.addDegreeError = "";
    },
    addDegreeFail(state, action) {
      state.addDegreeLoading = false;
      state.addDegreeError = action.payload;
    },

    updateDegreeRequest(state) {
      state.updateDegreeLoading = true;
      state.updateDegreeError = "";
    },
    updateDegreeSuccess(state, action) {
      state.updateDegreeLoading = false;
      state.updateDegreeError = "";
    },
    updateDegreeFail(state, action) {
      state.updateDegreeLoading = false;
      state.updateDegreeError = action.payload;
    },
  },
});

export const DegreeActions = DegreeSlice.actions;
export default DegreeSlice;
