import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogActions from "@mui/material/DialogActions";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import { addOffice } from "Redux/Offices/OfficesActions";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const DialogTitleRoot = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
  "& .closeButton": {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogTitle = (props) => {
  const { children, onClose } = props;
  return (
    <DialogTitleRoot disableTypography>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className="closeButton"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitleRoot>
  );
};

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  "&.root": { padding: theme.spacing(2) },
}));

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  "&.root": { margin: 0, padding: theme.spacing(1) },
}));

function AddOfficePopup() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const [officeName, setOfficeName] = useState("");

  const { addOfficeError, addOfficeLoading } = useSelector(
    (state) => state.officesReducer
  );

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add Office
      </Button>

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={"sm"}
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Add Offices
        </DialogTitle>

        <DialogContent dividers>
          <Typography gutterBottom>
            <TextField
              id="outlined-basic"
              label="Office Name"
              variant="outlined"
              type="text"
              fullWidth
              value={officeName}
              onChange={(e) => setOfficeName(e.target.value)}
            />
          </Typography>
          {addOfficeError && (
            <Typography gutterBottom className="errorText" color={"error"}>
              {addOfficeError}
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="warning">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              officeName != "" &&
                dispatch(addOffice(handleClose, { name: officeName }));
            }}
            color="primary"
            disabled={addOfficeLoading || officeName == ""}
          >
            {addOfficeLoading ? (
              <CircularProgress className="circleProgress" />
            ) : (
              "Save changes"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddOfficePopup;
