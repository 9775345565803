import React from "react";
import "./Sources.css";
import { Button } from "@mui/material";
import SourcesTable from "./SourcesTable";

function Sources() {
  return (
    <div className="sources_main">
      <div className="sources_top">
        <SourcesTable/>
      </div>
    </div>
  );
}

export default Sources;
