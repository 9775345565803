import { Button, TextField } from "@mui/material";
import { addFaculty } from "Redux/Universities/UniversitiesActions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddMajor from "./AddMajor";

function AddFaculty({ setShowFinish, showFinish }) {
  const { addFacultyLoading, addFacultyError, addFacultySuccess, newFaculty } =
    useSelector((state) => state.UniversitiesReducer);

  const dispatch = useDispatch();
  const [nbFac, setNbFac] = useState(1);
  const [faculties, setFaculties] = useState([]);
  const [facIndex, setFacIndex] = useState(-1);
  const [clicked, setClicked] = useState(false);
  const [done, setDone] = useState(false);
  const [doneFac, setDoneFac] = useState(0);
  const [addedFac, setAddedFac] = useState([]);
  const [error, setError] = useState("");
  const handleSave = () => {
    if (!faculties) {
      setError("Please fill all the fields");
    } else {
      setError("");
      const fac = {
        name: faculties,
        university: localStorage.getItem("uniId"),
      };
      setClicked(true);
      dispatch(addFaculty(fac));
    }
  };

  useEffect(() => {
    if (addFacultySuccess && clicked) {
      setClicked(false);
      setDone(true);

      const fac = { faculties, majors: [] };
      setAddedFac([...addedFac, fac]);
    }
  }, [addFacultySuccess, clicked]);

  const plusFac = () => {
    setDone(false);
    setDoneFac(0);
    setFaculties("");
  };

  const hasAtLeastOneMajorAndDegree = addedFac.every((faculty) => {
    const hasMajor = faculty.majors?.length > 0;
    const hasDegree = faculty.majors.every(
      (major) => major.degrees?.length > 0
    );
    return hasMajor && hasDegree;
  });
  useEffect(() => {
    if (hasAtLeastOneMajorAndDegree && addedFac.length > 0) {
      setShowFinish(true);
    } else {
      setShowFinish(false);
    }
  }, [addedFac]);

  useEffect(() => {
    if (addFacultyError) {
      setError(addFacultyError);
    }
  }, [addFacultyError]);

  return (
    <div className="add_faculty_main_div">
      <div className="add_facutly_header">
        <h3>Add Faculty</h3>
        {doneFac?.step2 == 1 && (
          <Button variant="contained" onClick={plusFac}>
            Add Faculty
          </Button>
        )}
      </div>
      {addedFac?.length > 0 && (
        <div className="add_major_body_div2">
          {addedFac?.map((deg, i) => (
            <ul>
              <li>
                Faculty{i + 1}: {deg.faculties}
              </li>
              {deg?.majors?.map((maj, j) => {
                return (
                  <ul>
                    <li>
                      Major{j + 1}: {maj.name}
                    </li>
                    {maj?.degrees?.map((deg, k) => {
                      return (
                        <ul>
                          <li>
                            Degree{k + 1}: {deg.name}
                          </li>
                        </ul>
                      );
                    })}
                  </ul>
                );
              })}
            </ul>
          ))}
        </div>
      )}
      <div className="add_faculty_body">
        {!done && (
          <div className="add_faculty_body_div">
            <TextField
              style={{ width: "30%" }}
              label="Faculty Name"
              disabled={done}
              onChange={(e) => {
                setFaculties(e.target.value);
              }}
            />
            {error && <div className="error_div">{error}</div>}

            {!done && (
              <>
                <Button
                  variant="contained"
                  onClick={() => handleSave()}
                  disabled={addFacultyLoading}
                >
                  Save
                </Button>

                {addedFac?.length > 0 && (
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setDone(true);
                      setDoneFac({ step2: true });
                    }}
                  >
                    Cancel
                  </Button>
                )}
              </>
            )}
          </div>
        )}
        {done && !doneFac.step2 && (
          <AddMajor
            addedFac={addedFac}
            setAddedFac={setAddedFac}
            facultyId={newFaculty.id}
            doneFac={doneFac}
            setDoneFac={setDoneFac}
          />
        )}
      </div>
    </div>
  );
}

export default AddFaculty;
