import * as React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Paper from "@mui/material/Paper";
import defaultImg from "../../../../Assets/Images/default_uni.jpg";
import "./UniversityAdd.css";
import { useState } from "react";
import { useRef } from "react";
import back from "../../../../Assets/Images/arrow1.webp";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Icon,
  IconButton,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { set } from "lodash";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCountries,
  getCitiesByContId,
} from "Redux/Location/LocationActions";
import { languagesList } from "app/utils/utils";
import AddFaculty from "./AddFaculty";
import {
  addSemesters,
  addUnivesity,
  getSingleUni,
} from "Redux/Universities/UniversitiesActions";
import { getAllProviders } from "Redux/Providers/ProvidersActions";
const steps = [
  {
    label: "Add University's Images",
  },
  {
    label: "Add University's Details",
  },
  {
    label: "Add University's Semesters",
  },
  {
    label: "Add University's Faculties",
  },
];
const semesters = [
  {
    value: "Fall",
    label: "Fall",
  },
  {
    value: "Spring",
    label: "Spring",
  },
  {
    value: "Summer",
    label: "Summer",
  },
];
const languages = languagesList;

function UniveristyAdd() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  /// codee

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [imageDataUrl, setImageDataUrl] = useState(null);
  const [logoImage, setLogoImage] = useState(null);

  const [name, setName] = useState("");
  const [founded, setFounded] = useState(null);
  const [nbDep, setNbDep] = useState("");
  const [selectedProvider, setselectedProvider] = useState(null);
  const [deadline, setDeadline] = useState(null);
  const [ranking, setRanking] = useState("");
  const [description, setDescription] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [availableSemester, setAvailableSemester] = useState({});
  const [showFinish, setShowFinish] = useState(false);
  const [selectedLang, setSelectedLang] = useState([]);
  const [deleteSem, setDeleteSem] = useState([]);

  const {
    cities,
    getCitiesLoading,
    getCitiesError,
    countries,
    getCountriesLoading,
    getCountriesError,
  } = useSelector((state) => state.LocationReducer);

  const {
    singleUniversity,
    adduniversityLoading,
    adduniversityError,
    adduniversitySuccess,
    newUniversity,
    addSemesterLoading,
    addSemesterError,
    addSemesterSuccess,
  } = useSelector((state) => state.UniversitiesReducer);

  const { allProviders } = useSelector((state) => state.providersReducer);
  const ref = useRef(null);
  const ref2 = useRef(null);

  const changeImage = () => {
    ref.current.click();
  };
  const changeImage2 = () => {
    ref2.current.click();
  };
  const onImageChange = (event) => {
    // if (event.target.files && event.target.files[0]) {
    //   dispatch(uploadPic(event.target.files[0]));
    // }

    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageDataUrl({ image: reader.result, file: file });
      };
      reader.readAsDataURL(file);
    }
  };
  const [newSemester, setNewSemester] = useState();

  const [show, setShow] = useState([]);
  const onAdd = () => {
    setShow([
      ...show,
      {
        name: newSemester?.value,
        start_date: null,
        deadline: null,
        active: true,
      },
    ]);
  };
  const handleDelete = (index) => {
    setDeleteSem([...deleteSem, show[index].id]);
    setShow((prevShow) => {
      const updatedShow = [...prevShow]; // Create a copy of the original array
      updatedShow.splice(index, 1); // Remove the element from the array
      return updatedShow; // Update the state with the new array
    });
  };

  const onLogoChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoImage({ image: reader.result, file: file });
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    dispatch(getAllCountries());
    dispatch(getAllProviders("", ""));
  }, []);

  useEffect(() => {
    if (selectedCountry) dispatch(getCitiesByContId(selectedCountry?.id));
  }, [selectedCountry]);

  useEffect(() => {
    if (adduniversitySuccess && activeStep == 1) {
      localStorage.setItem("uniId", newUniversity.id);
      dispatch(getSingleUni(newUniversity.id));
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [adduniversitySuccess]);
  useEffect(() => {
    if (addSemesterSuccess && activeStep == 2) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [addSemesterSuccess]);

  const handleNext = () => {
    if (activeStep == 0) {
      if (imageDataUrl == null || logoImage == null) {
        setError("Please add all the images");
      } else {
        setError("");
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep == 1) {
      if (
        name == "" ||
        founded == null ||
        nbDep == "" ||
        selectedCountry == null ||
        selectedCity == null ||
        deadline == null ||
        ranking == "" ||
        description == "" ||
        selectedLang.length == 0
      ) {
        setError("Please fill all the fields");
      } else {
        setError("");
        const summary = {
          Founded: founded.getFullYear(),
          Departments: nbDep,
        };
        const data = new FormData();
        data.append("logo", logoImage.file);
        data.append("image", imageDataUrl.file);
        data.append("name", name);
        data.append("country", selectedCountry.name);
        data.append("city", selectedCity.name);
        data.append("description", description);
        if (selectedProvider)
          data.append("pathway_provider", selectedProvider.id);

        const lang = [];
        selectedLang.map((item) => {
          lang.push(item.label);
        });
        data.append("languages", JSON.stringify(lang));
        data.append("ranking", ranking);
        data.append(
          "application_deadline",
          new Date(deadline).getFullYear() +
            "-" +
            (new Date(deadline).getMonth() + 1) +
            "-" +
            new Date(deadline).getDate()
        );
        data.append("accepting_applications", "true");
        data.append("summary", JSON.stringify(summary));

        dispatch(addUnivesity(data));
      }
    } else if (activeStep == 2) {
      if (show?.length == 0) {
        setError("Please add at least one semester");
      } else if (show?.length > 0) {
        if (
          show.filter(
            (item) => item?.deadline == null || !item?.start_data == null
          ).length > 0
        ) {
          setError("Please fill all the fields");
        } else {
          setError("");
          const data = [];
          const data2 = [];

          show.map((item) => {
            if (item.id) {
              data2.push({
                ...item,
                deadline:
                  new Date(item.deadline).getFullYear() +
                  "-" +
                  (new Date(item.deadline).getMonth() + 1) +
                  "-" +
                  new Date(item.deadline).getDate(),
                start_date:
                  new Date(item.start_date).getFullYear() +
                  "-" +
                  (new Date(item.start_date).getMonth() + 1) +
                  "-" +
                  new Date(item.start_date).getDate(),
                university: localStorage.getItem("uniId"),
              });
            } else {
              data.push({
                ...item,
                deadline:
                  new Date(item.deadline).getFullYear() +
                  "-" +
                  (new Date(item.deadline).getMonth() + 1) +
                  "-" +
                  new Date(item.deadline).getDate(),
                start_date:
                  new Date(item.start_date).getFullYear() +
                  "-" +
                  (new Date(item.start_date).getMonth() + 1) +
                  "-" +
                  new Date(item.start_date).getDate(),
                university: localStorage.getItem("uniId"),
              });
            }
          });

          if (data.length > 0) dispatch(addSemesters({ semesters: data }));
          if (data2.length > 0) dispatch(addSemesters(data2));
        }
      }
    } else if (activeStep == 3) {
      const uni = localStorage.getItem("uniId");
      localStorage.removeItem("uniId");

      navigate("/dashboard/universities/singleUni/" + uni);
    }
  };

  useEffect(() => {
    if (adduniversityError) {
      setError(adduniversityError);
    } else {
      setError("");
    }

    if (addSemesterError) {
      setError(addSemesterError);
    } else {
      setError("");
    }
  }, [adduniversityError, addSemesterError]);

  return (
    <Box
      width="95%"
      overflow="auto"
      margin="auto"
      marginTop="1rem"
      marginBottom="2rem"
    >
      {" "}
      <div className="uni_header">
        <img
          src={back}
          className="back_icon"
          onClick={() => navigate("/dashboard/universities")}
        />
        <h1>Add University </h1>
      </div>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === steps.length - 1 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>

            <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                {index == 0 && (
                  <div className="add_uni_step1">
                    <div className="add_uni_images">
                      <h3>Cover Image:</h3>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        style={{ display: "none" }}
                        ref={ref}
                        onChange={(e) => onImageChange(e)}
                      />
                      <div className="user_img" onClick={() => changeImage()}>
                        <img
                          src={
                            imageDataUrl === null
                              ? defaultImg
                              : imageDataUrl.image
                          }
                          alt="profile"
                          className="user_photo"
                        />
                        <IconButton className="user_photo_icon">
                          <Icon style={{ fontSize: "40px" }} color="primary">
                            add
                          </Icon>
                        </IconButton>{" "}
                      </div>
                    </div>

                    <div className="step_divider"></div>
                    <div className="add_uni_images">
                      <h3>Logo Image:</h3>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        style={{ display: "none" }}
                        ref={ref2}
                        onChange={(e) => onLogoChange(e)}
                      />
                      <div className="user_img" onClick={() => changeImage2()}>
                        <img
                          src={
                            logoImage === null ? defaultImg : logoImage.image
                          }
                          alt="profile"
                          className="user_photo2"
                        />
                        <IconButton className="user_photo_icon">
                          <Icon style={{ fontSize: "40px" }} color="primary">
                            add
                          </Icon>
                        </IconButton>{" "}
                      </div>
                    </div>
                  </div>
                )}

                {index == 1 && (
                  <div className="step2_uni_cont">
                    <DialogContent>
                      <div className="step2_uni_cont_fields">
                        <TextField
                          style={{ width: "30%" }}
                          label="University Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />

                        <Autocomplete
                          style={{ width: "30%" }}
                          options={countries}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Country"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                          value={selectedCountry}
                          onChange={(e, value) => {
                            setSelectedCountry(value);
                          }}
                        />

                        <Autocomplete
                          style={{ width: "30%" }}
                          options={cities}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="City"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                          value={selectedCity}
                          onChange={(e, value) => {
                            setSelectedCity(value);
                          }}
                        />
                        <Autocomplete
                          style={{ width: "30%" }}
                          options={allProviders?.providers}
                          getOptionLabel={(option) => option.provider}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Pathway Provider (optional)"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                          value={selectedProvider}
                          onChange={(e, value) => {
                            setselectedProvider(value);
                          }}
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Year Founded"
                            value={founded}
                            minDate={new Date("1800-01-01")}
                            onChange={(e) => setFounded(e)}
                            renderInput={(params) => (
                              <TextField {...params} style={{ width: "30%" }} />
                            )}
                            views={["year"]}
                            inputFormat="yyyy"
                            maxDate={new Date()}
                          />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Application Deadline"
                            value={deadline}
                            onChange={(e) => setDeadline(e)}
                            renderInput={(params) => (
                              <TextField {...params} style={{ width: "30%" }} />
                            )}
                            inputFormat="dd/MM/yyyy"
                          />
                        </LocalizationProvider>
                        <TextField
                          style={{ width: "30%" }}
                          label="Number of Departments"
                          type="number"
                          inputProps={{ min: 0 }}
                          value={nbDep}
                          onChange={(e) => setNbDep(e.target.value)}
                        />
                        <TextField
                          style={{ width: "30%" }}
                          label="Ranking"
                          type="number"
                          inputProps={{ min: 0 }}
                          value={ranking}
                          onChange={(e) => setRanking(e.target.value)}
                        />
                      </div>

                      <div className="step2_uni_semseters_main">
                        <h3>Languages: </h3>

                        <Autocomplete
                          style={{ width: "98%" }}
                          options={languages}
                          getOptionLabel={(option) => option.label}
                          multiple={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Languages"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                          value={selectedLang}
                          onChange={(e, value) => {
                            setSelectedLang(value);
                          }}
                        />
                      </div>
                      <div className="step2_uni_semseters_main">
                        <h3>Description: </h3>
                        <textarea
                          className="text_area_uni"
                          placeholder="University Description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>
                    </DialogContent>
                  </div>
                )}
                {index == 2 && (
                  <div className="step2_uni_semseters_main">
                    <div
                      className="add_facutly_header"
                      style={{ paddingBottom: "1rem" }}
                    >
                      <h3>Add Semester</h3>

                      <Autocomplete
                        style={{ width: "20%", padding: "0" }}
                        options={semesters}
                        getOptionLabel={(option) => option.value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            label="Add Semester"
                          />
                        )}
                        value={newSemester}
                        onChange={(e, value) => {
                          setNewSemester(value);
                        }}
                      />
                      <Button
                        variant="contained"
                        onClick={onAdd}
                        disabled={!newSemester}
                      >
                        Add
                      </Button>
                    </div>
                    <div className="step2_uni_semseters">
                      <div className="step2_uni_cont_fields">
                        {show.map((item, index) => {
                          return (
                            <div className="step2_uni_cont_fields">
                              <div style={{ width: "130px" }}>
                                {item?.name} Semester:
                              </div>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DatePicker
                                  label={item?.start_date && "Start Date"}
                                  value={
                                    item?.start_date
                                      ? new Date(item?.start_date)
                                        ? new Date(item?.start_date)
                                        : null
                                      : null
                                  }
                                  disabled={!item?.active}
                                  emptyLabel="Start Date"
                                  onChange={(e) => {
                                    setShow((prevShow) => {
                                      const updatedShow = [...prevShow]; // Create a copy of the original array
                                      updatedShow[index] = {
                                        ...updatedShow[index],
                                        start_date: e, // Update the deadline property
                                      };
                                      return updatedShow; // Update the state with the new array
                                    });
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      style={{ width: "30%" }}
                                    />
                                  )}
                                  inputFormat="dd/MM/yyyy"
                                />
                              </LocalizationProvider>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DatePicker
                                  label={item?.deadline && "Deadline"}
                                  emptyLabel="Deadline"
                                  value={
                                    item?.deadline
                                      ? new Date(item?.deadline)
                                        ? new Date(item?.deadline)
                                        : null
                                      : null
                                  }
                                  disabled={!item?.active}
                                  onChange={(e) => {
                                    setShow((prevShow) => {
                                      const updatedShow = [...prevShow]; // Create a copy of the original array
                                      updatedShow[index] = {
                                        ...updatedShow[index],
                                        deadline: e, // Update the deadline property
                                      };
                                      return updatedShow; // Update the state with the new array
                                    });
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      style={{ width: "30%" }}
                                    />
                                  )}
                                  inputFormat="dd/MM/yyyy"
                                />
                              </LocalizationProvider>
                              <FormControlLabel
                                label="Available"
                                control={
                                  <Switch
                                    color="primary"
                                    value="checkedB"
                                    checked={item?.active}
                                    onChange={(e) => {
                                      setShow((prevShow) => {
                                        const updatedShow = [...prevShow]; // Create a copy of the original array
                                        updatedShow[index] = {
                                          ...updatedShow[index],
                                          active: !updatedShow[index]?.active, // Toggle the active property
                                        };
                                        return updatedShow; // Update the state with the new array
                                      });
                                    }}
                                  />
                                }
                              />
                              <Button
                                color="error"
                                variant="contained"
                                onClick={() => handleDelete(index)}
                              >
                                Delete
                              </Button>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
                {index == 3 && (
                  <AddFaculty
                    setShowFinish={setShowFinish}
                    showFinish={showFinish}
                  />
                )}
                <div>
                  {error && <div className="error_div">{error}</div>}
                  {((index == 3 && showFinish) || index < 3) && (
                    <Button
                      variant="contained"
                      sx={{ mt: 1, mr: 1 }}
                      style={{ width: index == 3 && "100%" }}
                      onClick={handleNext}
                      disabled={adduniversityLoading || addSemesterLoading}
                    >
                      {index === steps.length - 1 ? "Finish" : "Continue"}
                    </Button>
                  )}
                  {/* {index != 0 && (
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                  )} */}
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

export default UniveristyAdd;
