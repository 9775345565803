import { Autocomplete, Button, TextField } from "@mui/material";
import { getAllMajorGroups } from "Redux/MajorCategories/MajorCategoriesActions";
import { addMajor } from "Redux/Universities/UniversitiesActions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddMajorDegree from "./AddMajorDegree";
function AddMajor({ facultyId, setDoneFac, doneFac, addedFac, setAddedFac }) {
  const dispatch = useDispatch();
  const [majors, setMajors] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [done, setDone] = useState(false);
  const [addedMajors, setAddedMajors] = useState([]);
  const [error, setError] = useState("");
  useEffect(() => {
    dispatch(getAllMajorGroups());
  }, []);
  const { addMajorLoading, addMajorError, addMajorSuccess, newMajor } =
    useSelector((state) => state.UniversitiesReducer);
  const [selectedGrp, setselectedGrp] = useState(null);
  const { allmajorcategories } = useSelector((state) => state.MajorCategories);
  const handleSave = () => {
    if (!majors || !selectedGrp) {
      setError("Please fill all the fields");
    } else {
      setError("");

      const maj = {
        name: majors,
        faculty: facultyId,
        major_group: selectedGrp.id,
      };
      setClicked(true);
      dispatch(addMajor(maj));
    }
  };

  useEffect(() => {
    if (addMajorSuccess && clicked) {
      setClicked(false);
      setDone(true);
      setDoneFac(2);

      //add the major to the last faculty in the list
      const updatedObject = [...addedFac];
      const lastFaculty = updatedObject[updatedObject.length - 1];

      if (lastFaculty.majors) {
        lastFaculty.majors = [...lastFaculty.majors, { name: majors }];
      } else {
        lastFaculty.majors = [{ name: majors }];
      }

      setAddedFac(updatedObject);
    }
  }, [addMajorSuccess, clicked]);

  const plusMajor = () => {
    setDone(false);

    setMajors("");
    setselectedGrp(null);
  };
  useEffect(() => {
    if (addMajorError) {
      setError(addMajorError);
    }
  }, [addMajorError]);
  return (
    <div className="add_major_main_div">
      <div className="add_major_header">
        <h3>Add Major</h3>
        {doneFac?.step3 && (
          <>
            <Button variant="contained" onClick={plusMajor}>
              Add Major
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() =>
                setDoneFac({
                  step2: true,
                })
              }
            >
              Finish adding majors
            </Button>
          </>
        )}
      </div>

      <div className="add_major_body">
        {!done && (
          <div className="add_major_body_div">
            <TextField
              style={{ width: "30%" }}
              label="Major Name"
              disabled={done}
              onChange={(e) => {
                setMajors(e.target.value);
              }}
            />
            {allmajorcategories?.length > 0 && (
              <Autocomplete
                style={{ width: "30%" }}
                options={allmajorcategories}
                getOptionLabel={(option) => option.name}
                disabled={done}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Major Group"
                    variant="outlined"
                    fullWidth
                  />
                )}
                value={selectedGrp}
                onChange={(e, value) => {
                  setselectedGrp(value);
                }}
              />
            )}
            {error && <div className="error_div">{error}</div>}

            {!done && (
              <>
                {" "}
                <Button
                  variant="contained"
                  onClick={() => handleSave()}
                  disabled={addMajorLoading}
                >
                  Save
                </Button>
                {addedFac[addedFac?.length - 1].majors?.length > 0 && (
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setDone(true);
                      setDoneFac({ step3: true });
                    }}
                  >
                    Cancel
                  </Button>
                )}
              </>
            )}
          </div>
        )}
        {done && !doneFac.step3 && (
          <AddMajorDegree
            majorId={newMajor.id}
            doneFac={doneFac}
            setDoneFac={setDoneFac}
            setAddedFac={setAddedFac}
            addedFac={addedFac}
          />
        )}
      </div>
    </div>
  );
}

export default AddMajor;
