import { axiosReq } from "app/utils/utils";
import { OfficesActions } from "./OfficesReducer";

export const getAllOffices = () => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/admin/offices`;
  dispatch(OfficesActions.fetchAllOfficesRequest());
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success) {
      dispatch(OfficesActions.fetchAllOfficesSuccess(res.data));
    } else dispatch(OfficesActions.fetchAllOfficesFail(res.data.message));
  });
};

export const addOffice = (handleClose, data) => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/admin/office`;
  dispatch(OfficesActions.addOfficeRequest());
  dispatch(axiosReq("post", url, {}, data)).then((res) => {
    if (res.success) {
      dispatch(OfficesActions.addOfficeSuccess());
      handleClose();
      dispatch(getAllOffices());
    } else dispatch(OfficesActions.addOfficeFail(res.data.message));
  });
};

export const editOffice = (handleClose, data, id) => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/admin/office/` + id;
  dispatch(OfficesActions.editOfficeRequest());
  dispatch(axiosReq("patch", url, {}, data)).then((res) => {
    if (res.success) {
      dispatch(OfficesActions.editOfficeSuccess());
      handleClose();
      dispatch(getAllOffices());
    } else dispatch(OfficesActions.editOfficeFail(res.data.message));
  });
};

export const deleteOffice = (handleClose, data, id) => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/admin/office/` + id;
  dispatch(OfficesActions.deleteOfficeRequest());
  dispatch(axiosReq("delete", url, {}, { newOfficeId: data.id })).then(
    (res) => {
      if (res.success) {
        dispatch(OfficesActions.deleteOfficeSuccess());
        handleClose();
        dispatch(getAllOffices());
      } else dispatch(OfficesActions.deleteOfficeFail(res.data.message));
    }
  );
};
