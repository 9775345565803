/** @format */

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  cities: [],
  getCitiesLoading: false,
  getCitiesError: "",

  countries: [],
  getCountriesLoading: false,
  getCountriesError: "",
};

const LocationSlice = createSlice({
  name: "Location",
  initialState,
  reducers: {
    getCountriesRequest(state) {
      state.getCountriesLoading = true;
      state.getCountriesError = "";
      state.countries = [];
    },
    getCountriesSuccess(state, action) {
      state.getCountriesLoading = false;
      state.getCountriesError = "";
      state.countries = action.payload;
    },
    getCountriesFail(state, action) {
      state.getCountriesLoading = false;
      state.getCountriesError = action.payload;
      state.countries = [];
    },

    getCitiesRequest(state) {
      state.getCitiesLoading = true;
      state.getCitiesError = "";
      state.cities = [];
    },
    getCitiesSuccess(state, action) {
      state.getCitiesLoading = false;
      state.getCitiesError = "";
      state.cities = action.payload;
    },
    getCitiesFail(state, action) {
      state.getCitiesLoading = false;
      state.getCitiesError = action.payload;
      state.cities = [];
    },
  },
});

export const LocationActions = LocationSlice.actions;
export default LocationSlice;
