/** @format */

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  user: {},
  success: false,
  getUserLoading: false,
  getUserError: "",

  loginLoading: false,
  loginError: "",
  loginSuccess: false,

  logoutLoading: false,
  logoutError: "",
  logoutSuccess: false,

  editUserLoading: false,
  editUserError: "",
  editUserSuccess: false,

  changePasswordLoading: false,
  changePasswordError: "",
  changePasswordSuccess: false,
};

const UserSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    GetuserRequest(state) {
      state.getUserLoading = true;
      state.getUserError = "";
      state.user = {};
    },
    GetuserSuccess(state, action) {
      state.getUserLoading = false;
      state.getUserError = "";
      state.user = action.payload;
    },
    GetuserFail(state, action) {
      state.getUserLoading = false;
      state.getUserError = action.payload;
      state.user = {};
    },
    //login user
    loginUserRequest(state) {
      state.loginLoading = true;
      state.loginError = "";
      state.loginSuccess = false;
    },
    loginUserSuccess(state, action) {
      state.loginLoading = false;
      state.loginError = "";
      state.loginSuccess = true;
    },
    loginUserFail(state, action) {
      state.loginLoading = false;
      state.loginError = action.payload;
      state.loginSuccess = false;
    },
    //logout user
    logoutUserRequest(state) {
      state.logoutLoading = true;
      state.logoutError = "";
      state.logoutSuccess = false;
    },
    logoutUserSuccess(state, action) {
      state.logoutLoading = false;
      state.logoutError = "";
      state.logoutSuccess = true;
    },
    logoutUserFail(state, action) {
      state.logoutLoading = false;
      state.logoutError = action.payload;
      state.logoutSuccess = false;
    },

    //edit user
    editUserRequest(state) {
      state.editUserLoading = true;
      state.editUserError = "";
      state.editUserSuccess = false;
    },
    editUserSuccess(state, action) {
      state.editUserLoading = false;
      state.editUserError = "";
      state.editUserSuccess = true;
    },
    editUserFail(state, action) {
      state.editUserLoading = false;
      state.editUserError = action.payload;
      state.editUserSuccess = false;
    },

    //change password
    changePasswordRequest(state) {
      state.changePasswordLoading = true;
      state.changePasswordError = "";
      state.changePasswordSuccess = false;
    },
    changePasswordSuccess(state, action) {
      state.changePasswordLoading = false;
      state.changePasswordError = "";
      state.changePasswordSuccess = true;
    },
    changePasswordFail(state, action) {
      state.changePasswordLoading = false;
      state.changePasswordError = action.payload;
      state.changePasswordSuccess = false;
    },
  },
});

export const UserActions = UserSlice.actions;
export default UserSlice;
