import { Title } from "@mui/icons-material";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Loading from "app/components/MatxLoading";
import {
  GetNewsLetter,
  getstudentssubsc,
} from "../../Redux/NewsLetter/NewsLetterActions";
import PopUpAddIndiv from "./PopUpAddIndiv";
import Pagination from "Components/Pagination/Pagination";
import PopUpAdd from "./PopUpAdd";
const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));

const NewsLettersTable = () => {
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [limit, setlimit] = useState(10);
  const [offset, setoffset] = useState(0);
  const {
    NewsLetter,
    getNewsLetterLoading,
    getNewsLetterError,
    createNewsLetterLoading,
    studentssubscribed,
    addindividualstudentLoading,
  } = useSelector((state) => state.NewsLetterReducer);
  useEffect(() => {
    dispatch(GetNewsLetter(limit, offset));
  }, [limit, offset, page]);
  useEffect(() => {
    dispatch(getstudentssubsc());
  }, []);

  const [addmodal, setaddmodal] = useState(false);
  const [sendindiv, setsendindiv] = useState(false);
  return (
    <>
      {getNewsLetterLoading ||
      createNewsLetterLoading ||
      addindividualstudentLoading ? (
        <Loading />
      ) : (
        <>
          <Box width="95%" overflow="auto" margin="auto" marginTop="2rem">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h1> News Letters </h1>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setaddmodal(true)}
                >
                  Send A Newsletter
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setsendindiv(true)}
                >
                  Send Individual Letter
                </Button>
              </div>
            </div>

            <StyledTable>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Subject</TableCell>
                  <TableCell align="left">Message</TableCell>
                  <TableCell align="center">Sent At</TableCell>
                  {/* <TableCell align="center">Actions</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {NewsLetter?.newsletters?.map((subscriber) => (
                  <TableRow key={subscriber.id}>
                    <TableCell align="left">{subscriber.subject}</TableCell>
                    <TableCell align="left">{subscriber.message}</TableCell>
                    <TableCell align="center">
                      {subscriber.createdAt.slice(0, 10)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
            {addmodal && <PopUpAdd setopen={setaddmodal} open={addmodal} />}
            {sendindiv && (
              <PopUpAddIndiv
                setopen={setsendindiv}
                open={sendindiv}
                options={studentssubscribed.map((el) => {
                  return { label: el.email, value: el.email };
                })}
              />
            )}
            <Pagination
              limit={limit}
              offset={offset}
              setOffset={setoffset}
              pageNumber={page}
              setPageNumber={setPage}
              hasPrev={NewsLetter.previous}
              hasNext={NewsLetter.next}
            />
          </Box>
          {getNewsLetterError && (
            <div style={{ fontSize: "1.6rem", color: "red" }}>
              {getNewsLetterError}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default NewsLettersTable;
