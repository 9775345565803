import { Card, Grid, styled, useTheme } from "@mui/material";
import { Fragment } from "react";

import Tablenews from "./NewsLettersTable";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../Redux/User/UserActions";
import { useNavigate } from "react-router-dom";

const ContentBox = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
}));

const NewsLetter = () => {
  const { palette } = useTheme();
  const role = useSelector((state) => state.User?.user?.role);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (role && role !== "admin") {
      dispatch(logoutUser(navigate));
    }
  }, [role]);
  return (
    <Fragment>
      <ContentBox className="analytics">
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <Tablenews />
        </Grid>
      </ContentBox>
    </Fragment>
  );
};

export default NewsLetter;
