import React from "react";
import arrow1 from "../../Assets/Images/arrow1.webp";
import arrow2 from "../../Assets/Images/arrow2.webp";

import "./Pagination.css";

function Pagination({
  limit,
  offset,
  setOffset,
  pageNumber,
  setPageNumber,
  hasPrev,
  hasNext,
}) {
  return (
    <div className="paginationContainer">
      {hasPrev && (
        <img
          src={arrow1}
          onClick={() => {
            setOffset(offset - limit);
            setPageNumber(pageNumber - 1);
          }}
          className="PaginationButton"
        />
      )}

      <span>Page {pageNumber}</span>
      {hasNext && (
        <img
          src={arrow2}
          onClick={() => {
            setOffset(offset + limit);
            setPageNumber(pageNumber + 1);
          }}
          className="PaginationButton"
        />
      )}
    </div>
  );
}
export default Pagination;
