/** @format */

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  student: [],
  getstudentLoading: false,
  getstudentError: "",
  staff: [],
  getstaffLoading: false,
  getstaffError: "",
  allapplications: [],
  getallapplicationsLoading: false,
  getallapplicationsError: "",
  leads: [],
  getleadsLoading: false,
  getleadsError: "",
};

const StatsSlice = createSlice({
  name: "Stats",
  initialState,
  reducers: {
    GetStudentsRequest(state) {
      state.getstudentLoading = true;
      state.getstudentError = "";
      state.student = {};
    },
    GetStudentsSuccess(state, action) {
      state.getstudentLoading = false;
      state.getstudentError = "";
      state.student = action.payload;
    },
    GetStudentsFail(state, action) {
      state.getstudentLoading = false;
      state.getstudentError = action.payload;
      state.student = {};
    },
    getstaffRequest(state) {
      state.getstaffLoading = true;
      state.getstaffError = "";
      state.staff = {};
    },
    getstaffSuccess(state, action) {
      state.getstaffLoading = false;
      state.getstaffError = "";
      state.staff = action.payload;
    },
    getstaffFail(state, action) {
      state.getstaffLoading = false;
      state.getstaffError = action.payload;
      state.staff = {};
    },
    getallapplicationsRequest(state) {
      state.getallapplicationsLoading = true;
      state.getallapplicationsError = "";
      state.allapplications = {};
    },
    getallapplicationsSuccess(state, action) {
      state.getallapplicationsLoading = false;
      state.getallapplicationsError = "";
      state.allapplications = action.payload;
    },
    getallapplicationsFail(state, action) {
      state.getallapplicationsLoading = false;
      state.getallapplicationsError = action.payload;
      state.allapplications = {};
    },
    getleadsRequest(state) {
      state.getleadsLoading = true;
      state.getleadsError = "";
      state.leads = {};
    },
    getleadsSuccess(state, action) {
      state.getleadsLoading = false;
      state.getleadsError = "";
      state.leads = action.payload;
    },
    getleadsFail(state, action) {
      state.getleadsLoading = false;
      state.getleadsError = action.payload;
      state.leads = {};
    },
  },
});

export const StatsActions = StatsSlice.actions;
export default StatsSlice;
