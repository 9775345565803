import { axiosReq } from "app/utils/utils";
import { StudentsActions } from "./StudentsReducers";

export const GetStudents = (limit, offset) => async (dispatch) => {
  dispatch(StudentsActions.GetStudentsRequest());
  dispatch(
    axiosReq(
      "get",
      process.env.REACT_APP_API + `/sales/students`,
      {},
      {},
      { limit: limit, offset: offset }
    )
  ).then((res) => {
    if (res.success) dispatch(StudentsActions.GetStudentsSuccess(res.data));
    else
      dispatch(StudentsActions.GetStudentsFail(res.data.response.data.message));
  });
};

export const GetSingleStudent = (id) => async (dispatch) => {
  dispatch(StudentsActions.getsinglestudentRequest());
  dispatch(
    axiosReq("get", process.env.REACT_APP_API + `/student/${id}`, {}, {})
  ).then((res) => {
    if (res.success)
      dispatch(StudentsActions.getsinglestudentSuccess(res.data));
    else
      dispatch(
        StudentsActions.getsinglestudentFail(res.data.response.data.message)
      );
  });
};
