import { axiosReq } from "app/utils/utils";
import { UniversitiesActions } from "./UniversitiesReducer";

export const GetAllUniversities = (limit, offset) => async (dispatch) => {
  let url = process.env.REACT_APP_API + `/university/all`;

  dispatch(UniversitiesActions.getuniversitiesRequest());
  dispatch(axiosReq("get", url, {}, {}, { limit: limit, offset: offset })).then(
    (res) => {
      if (res.success)
        dispatch(UniversitiesActions.getuniversitiesSuccess(res.data));
      else
        dispatch(
          UniversitiesActions.getuniversitiesFail(
            res.data.response.data.message
          )
        );
    }
  );
};

export const editUniversity = (id, body, limit, offset) => async (dispatch) => {
  let url = process.env.REACT_APP_API + `/university/${id}`;

  dispatch(UniversitiesActions.edituniversityRequest());
  dispatch(axiosReq("patch", url, {}, body)).then((res) => {
    if (res.success) {
      dispatch(UniversitiesActions.edituniversitySuccess(res.data));
      if (limit || offset) {
        dispatch(GetAllUniversities(limit, offset));
      }
    } else
      dispatch(
        UniversitiesActions.edituniversityFail(res.data.response.data.message)
      );
  });
};

export const getSingleUni = (id) => async (dispatch) => {
  let url = process.env.REACT_APP_API + `/university/${id}`;

  dispatch(UniversitiesActions.getSingleUniversityRequest());
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success)
      dispatch(UniversitiesActions.getSingleUniversitySuccess(res.data));
    else
      dispatch(
        UniversitiesActions.getSingleUniversityFail(
          res.data.response.data.message
        )
      );
  });
};

export const getMajors = (id) => async (dispatch) => {
  let url = process.env.REACT_APP_API + `/major/faculty/${id}`;

  dispatch(UniversitiesActions.getMajorsRequest());
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success) {
      dispatch(UniversitiesActions.getMajorsSuccess(res.data));
    } else
      dispatch(
        UniversitiesActions.getMajorsFail(res.data.response.data.message)
      );
  });
};

export const getDegree = (id) => async (dispatch) => {
  let url = process.env.REACT_APP_API + `/degree/${id}`;

  dispatch(UniversitiesActions.getDegreesRequest());
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success) {
      dispatch(UniversitiesActions.getDegreesSuccess(res.data));
    } else
      dispatch(
        UniversitiesActions.getDegreesFail(res.data.response.data.message)
      );
  });
};

export const getMajorDegree = (Major_id, Degree_id) => async (dispatch) => {
  let url = process.env.REACT_APP_API + `/majorDegree/${Major_id}/${Degree_id}`;

  dispatch(UniversitiesActions.getMajorDegreeRequest());
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success) {
      dispatch(UniversitiesActions.getMajorDegreeSuccess(res.data));
    } else
      dispatch(
        UniversitiesActions.getMajorDegreeFail(res.data.response.data.message)
      );
  });
};

export const addUnivesity = (body) => async (dispatch) => {
  let url = process.env.REACT_APP_API + `/university`;

  dispatch(UniversitiesActions.adduniversityRequest());
  dispatch(axiosReq("post", url, {}, body)).then((res) => {
    if (res.success) {
      dispatch(UniversitiesActions.adduniversitySuccess(res.data));
    } else
      dispatch(
        UniversitiesActions.adduniversityFail(res.data.response.data.message)
      );
  });
};

export const addSemesters = (body) => async (dispatch) => {
  let url = process.env.REACT_APP_API + `/university/semester`;

  dispatch(UniversitiesActions.addSemesterRequest());
  dispatch(axiosReq("post", url, {}, body)).then((res) => {
    if (res.success) {
      dispatch(UniversitiesActions.addSemesterSuccess(res.data));
    } else
      dispatch(
        UniversitiesActions.addSemesterFail(res.data.response.data.message)
      );
  });
};

export const editSemesters = (body) => async (dispatch) => {
  let url = process.env.REACT_APP_API + `/university/semesters`;
  dispatch(UniversitiesActions.editSemesterRequest());
  dispatch(axiosReq("patch", url, {}, body)).then((res) => {
    if (res.success) {
      dispatch(UniversitiesActions.editSemesterSuccess(res.data));
    } else
      dispatch(
        UniversitiesActions.editSemesterFail(res.data.response.data.message)
      );
  });
};

export const deleteSemesters = (body) => async (dispatch) => {
  let url = process.env.REACT_APP_API + `/university/semester/delete`;
  dispatch(UniversitiesActions.deleteSemesterRequest());
  dispatch(axiosReq("post", url, {}, body)).then((res) => {
    if (res.success) {
      dispatch(UniversitiesActions.deleteSemesterSuccess(res.data));
    } else
      dispatch(
        UniversitiesActions.deleteSemesterFail(res.data.response.data.message)
      );
  });
};

export const addFaculty = (body, uniId) => async (dispatch) => {
  let url = process.env.REACT_APP_API + `/faculty`;

  dispatch(UniversitiesActions.addFacultyRequest());
  dispatch(axiosReq("post", url, {}, body)).then((res) => {
    if (res.success) {
      dispatch(UniversitiesActions.addFacultySuccess(res.data));
      if (uniId) {
        dispatch(getSingleUni(uniId, "", ""));
      }
    } else
      dispatch(
        UniversitiesActions.addFacultySuccess(res.data.response.data.message)
      );
  });
};

export const addMajor = (body, facId) => async (dispatch) => {
  let url = process.env.REACT_APP_API + `/major`;

  dispatch(UniversitiesActions.addMajorRequest());
  dispatch(axiosReq("post", url, {}, body)).then((res) => {
    if (res.success) {
      dispatch(UniversitiesActions.addMajorSuccess(res.data));
      if (facId) {
        dispatch(getMajors(facId));
      }
    } else
      dispatch(
        UniversitiesActions.addMajorFail(res.data.response.data.message)
      );
  });
};

export const addMajorDegree = (body, majId) => async (dispatch) => {
  let url = process.env.REACT_APP_API + `/majorDegree`;

  dispatch(UniversitiesActions.addMajorDegreeRequest());
  dispatch(axiosReq("post", url, {}, body)).then((res) => {
    if (res.success) {
      dispatch(UniversitiesActions.addMajorDegreeSuccess(res.data));
      if (majId) {
        dispatch(getDegree(majId));
      }
    } else
      dispatch(
        UniversitiesActions.addMajorDegreeFail(res.data.response.data.message)
      );
  });
};

export const editFaculty = (id, body, uniId) => async (dispatch) => {
  let url = process.env.REACT_APP_API + `/faculty/${id}`;
  dispatch(UniversitiesActions.editFacultyRequest());
  dispatch(axiosReq("patch", url, {}, body)).then((res) => {
    if (res.success) {
      dispatch(UniversitiesActions.editFacultySuccess(res.data));
      dispatch(getSingleUni(uniId, "", ""));
    } else
      dispatch(
        UniversitiesActions.editFacultyFail(res.data.response.data.message)
      );
  });
};

export const editMajor = (id, body, facId) => async (dispatch) => {
  let url = process.env.REACT_APP_API + `/major/${id}`;
  dispatch(UniversitiesActions.editMajorRequest());
  dispatch(axiosReq("patch", url, {}, body)).then((res) => {
    if (res.success) {
      dispatch(UniversitiesActions.editMajorSuccess(res.data));
      dispatch(getMajors(facId));
    } else
      dispatch(
        UniversitiesActions.editMajorFail(res.data.response.data.message)
      );
  });
};

export const editMajorDegree = (id, body, majId) => async (dispatch) => {
  let url = process.env.REACT_APP_API + `/majorDegree/${id}`;
  dispatch(UniversitiesActions.editMajorDegreeRequest());
  dispatch(axiosReq("patch", url, {}, body)).then((res) => {
    if (res.success) {
      dispatch(UniversitiesActions.editMajorDegreeSuccess(res.data));
      dispatch(getDegree(majId));
    } else
      dispatch(
        UniversitiesActions.editMajorDegreeFail(res.data.response.data.message)
      );
  });
};
