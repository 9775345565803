import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addMajorGroup,
  editMajorGroup,
  getAllMajorGroups,
} from "Redux/MajorCategories/MajorCategoriesActions";
import defaultImg from "../../Assets/Images/default_uni.jpg";

import "./MajorCategories.css";
import { MatxLoading } from "app/components";
import MuiDialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { set } from "lodash";
import DropDown from "Components/DropDown/DropDown";

const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));

function MajorCategoriesTable() {
  const DialogTitleRoot = styled(MuiDialogTitle)(({ theme }) => ({
    margin: 0,
    padding: theme.spacing(2),
    "& .closeButton": {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }));

  const DialogTitle = (props) => {
    const { children, onClose } = props;
    return (
      <DialogTitleRoot disableTypography>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="Close"
            className="closeButton"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitleRoot>
    );
  };
  const dispatch = useDispatch();
  const [openAdd, setOpenAdd] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [edit, setEdit] = useState(false);
  const [majorId, setMajorId] = useState(-1);
  const [major, setMajor] = useState({});
  const {
    getallmajorcategoriesLoading,
    allmajorcategories,
    addmajorcategoryLoading,
    addmajorcategoryError,
    addmajorcategorySuccess,
    editmajorcategoryLoading,
    editmajorcategoryError,
    editmajorcategorySuccess,
  } = useSelector((state) => state.MajorCategories);
  const [imageDataUrl, setImageDataUrl] = useState(null);
  const ref = useRef(null);
  const onImageChange = (event) => {
    // if (event.target.files && event.target.files[0]) {
    //   dispatch(uploadPic(event.target.files[0]));
    // }

    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageDataUrl({ image: reader.result, file: file });
      };
      reader.readAsDataURL(file);
    }
  };
  const changeImage = () => {
    ref.current.click();
  };
  useEffect(() => {
    dispatch(getAllMajorGroups());
  }, []);

  const addMajor = () => {
    setClicked(true);

    let formData = new FormData();
    formData.append("name", major?.name);
    formData.append("description", major?.description);
    formData.append("category", major?.category?.value);
    formData.append("top_careers", major?.top_careers);
    if (imageDataUrl?.file) {
      formData.append("image", imageDataUrl?.file);
    }

    if (edit) {
      dispatch(editMajorGroup(majorId, formData));
    } else {
      dispatch(addMajorGroup(formData));
    }
  };

  useEffect(() => {
    if ((addmajorcategorySuccess || editmajorcategorySuccess) && clicked) {
      setOpenAdd(false);
      setClicked(false);
    }
  }, [clicked, addmajorcategorySuccess, editmajorcategorySuccess]);

  const openEdit = (i) => {
    setEdit(true);
    setOpenAdd(true);

    setMajor({
      ...allmajorcategories[i],
      category: {
        value: allmajorcategories[i].category,
        label: allmajorcategories[i].category,
      },
      top_careers: allmajorcategories[i].top_careers.join(","),
    });
    setImageDataUrl({
      image:
        process.env.REACT_APP_API +
        "/files/major/group/" +
        allmajorcategories[i].image,
    });
    setMajorId(allmajorcategories[i].id);
  };

  const categories = [
    {
      value: "Creative and Artistic",
      label: "Creative and Artistic",
    },
    {
      value: "Analytical and Investigative",
      label: "Analytical and Investigative",
    },
    { value: "Social and Helping", label: "Social and Helping" },
    {
      value: "Enterprising and Leadership",
      label: "Enterprising and Leadership",
    },
    {
      value: "Conventional and Organizational Interests",
      label: "Conventional and Organizational Interests",
    },
  ];

  useEffect(() => {
    if (!edit) {
      setMajor({});
      setImageDataUrl(null);
    }
  }, [edit]);

  return (
    <Box width="95%" overflow="auto" margin="auto" marginTop="2rem">
      {getallmajorcategoriesLoading || addmajorcategoryLoading ? (
        <MatxLoading />
      ) : (
        <>
          <div className="header_major_grp">
            <h1>Major Groups </h1>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setOpenAdd(true);
                setEdit(false);
              }}
            >
              Add Major Group
            </Button>
          </div>
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell align="left">Name</TableCell>
                <TableCell align="center">Created At</TableCell>
                <TableCell align="center">Updated At</TableCell>

                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allmajorcategories &&
                allmajorcategories?.length > 0 &&
                allmajorcategories?.map((el, index) => (
                  <TableRow key={index}>
                    <TableCell align="left">{el.name}</TableCell>

                    <TableCell align="center">
                      {new Date(el.createdAt).getUTCDay()}/
                      {new Date(el.createdAt).getMonth() + 1}/
                      {new Date(el.createdAt).getFullYear()}
                    </TableCell>
                    <TableCell align="center">
                      {new Date(el.updatedAt).getUTCDay()}/
                      {new Date(el.updatedAt).getMonth() + 1}/
                      {new Date(el.updatedAt).getFullYear()}
                    </TableCell>

                    <TableCell align="right">
                      <IconButton>
                        <Icon color="primary" onClick={() => openEdit(index)}>
                          edit
                        </Icon>
                      </IconButton>{" "}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </StyledTable>

          {openAdd && (
            <Dialog
              maxWidth="md"
              onClose={() => setOpenAdd(false)}
              aria-labelledby="customized-dialog-title"
              open={openAdd}
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={() => setOpenAdd(false)}
              >
                Add Major Group
              </DialogTitle>
              <DialogContent dividers>
                <div
                  className="add_major_grp_inputs"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "1rem",
                  }}
                >
                  <TextField
                    className="add_major_grp_input"
                    label="Major Name"
                    value={major?.name}
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                    onChange={(e) =>
                      setMajor({ ...major, name: e.target.value })
                    }
                  />

                  <DropDown
                    className="add_major_grp_input"
                    title="Career Test Category"
                    suggestions={categories}
                    setselected={(e) => setMajor({ ...major, category: e })}
                    selected={major?.category}
                  />
                  <div
                    className="step2_uni_semseters_main"
                    style={{ margin: 0 }}
                  >
                    <h3 style={{ marginTop: "0" }}>Description: </h3>
                    <textarea
                      style={{
                        width: "41rem",
                      }}
                      className="text_area_uni"
                      placeholder="Major Description"
                      value={major?.description}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                      onChange={(e) =>
                        setMajor({ ...major, description: e.target.value })
                      }
                    />
                  </div>
                  <div
                    className="step2_uni_semseters_main"
                    style={{ margin: 0 }}
                  >
                    <h3 style={{ marginTop: "0" }}>
                      Top Careers (separated by commas):{" "}
                    </h3>
                    <textarea
                      style={{
                        width: "41rem",
                      }}
                      className="text_area_uni"
                      placeholder="Top Careers (separated by commas)"
                      value={major?.top_careers}
                      validators={["required"]}
                      errorMessages={["This field is required"]}
                      onChange={(e) =>
                        setMajor({ ...major, top_careers: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="add_uni_images">
                  <div className="add_uni_images">
                    <h3>Cover Image:</h3>
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      style={{ display: "none" }}
                      ref={ref}
                      onChange={(e) => onImageChange(e)}
                    />
                    <div className="user_img" onClick={() => changeImage()}>
                      <img
                        src={
                          imageDataUrl === null
                            ? defaultImg
                            : imageDataUrl.image
                        }
                        alt="profile"
                        className="user_photo"
                        style={{
                          width: "41rem",
                          height: "10rem",
                        }}
                      />
                      <IconButton className="user_photo_icon">
                        <Icon style={{ fontSize: "40px" }} color="primary">
                          add
                        </Icon>
                      </IconButton>{" "}
                    </div>
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenAdd(false)}
                  variant="contained"
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={major?.name == "" || major?.description == ""}
                  onClick={() => addMajor()}
                >
                  {edit ? "Save changes" : "Add Major Group"}
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </>
      )}
    </Box>
  );
}

export default MajorCategoriesTable;
