import { Alert, Button, Snackbar } from "@mui/material";
import { amber, green } from "@mui/material/colors";
import { styled } from "@mui/system";
import React from "react";

const ContentRoot = styled("div")(({ theme }) => ({
  "& .icon": { fontSize: 20 },
  "& .success": { backgroundColor: green[600] },
  "& .warning": { backgroundColor: amber[700] },
  "& .error": { backgroundColor: theme.palette.error.main },
  "& .info": { backgroundColor: theme.palette.primary.main },
  "& .iconVariant": { opacity: 0.9, marginRight: theme.spacing(1) },
  "& .message": { display: "flex", alignItems: "center" },
  "& .margin": { margin: theme.spacing(1) },
}));

export default function SnackBar({ setOpen, open, message, type }) {
  //   const [open, setOpen] = React.useState(false);

  function handleClose(_, reason) {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  }

  return (
    <ContentRoot>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%" }}
          variant="filled"
        >
          {message}
        </Alert>
      </Snackbar>
      {type === "error" && (
        <Alert
          onClose={handleClose}
          sx={{ m: 1 }}
          severity="error"
          variant="filled"
        >
          {message}
        </Alert>
      )}

      {type === "warning" && (
        <Alert
          onClose={handleClose}
          sx={{ m: 1 }}
          severity="warning"
          variant="filled"
        >
          {message}
        </Alert>
      )}

      {type === "info" && (
        <Alert
          onClose={handleClose}
          sx={{ m: 1 }}
          severity="info"
          variant="filled"
        >
          {message}
        </Alert>
      )}
      {type === "success" && (
        <Alert
          onClose={handleClose}
          sx={{ m: 1 }}
          severity="success"
          variant="filled"
        >
          {message}
        </Alert>
      )}
    </ContentRoot>
  );
}
