import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import EditOfficePopup from "./EditOfficePopup";
import PopUpDel from "../../Components/DeletePop/PopUpDel";
import { useState } from "react";
import DeleteOfficePopup from "./DeleteOfficePopup";

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
  },
}));

function OfficesTable() {
  const { allOffices } = useSelector((state) => state.officesReducer);

  const [openDelte, setOpenDelte] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState();

  return (
    <Box width="100%" overflow="auto">
      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell align="left">Name</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {allOffices.map((office, index) => (
            <TableRow key={index}>
              <TableCell align="left">{office.name}</TableCell>
              <TableCell align="right">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <EditOfficePopup office={office} />

                  <DeleteOfficePopup office={office} />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </Box>
  );
}

export default OfficesTable;
