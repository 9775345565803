import { axiosReq } from "app/utils/utils";
import { UserActions } from "./UserReducers";

export const getUser = () => async (dispatch) => {
  dispatch(UserActions.GetuserRequest());
  dispatch(
    axiosReq("get", process.env.REACT_APP_API + "/user/me", {}, {})
  ).then((res) => {
    if (res.success) dispatch(UserActions.GetuserSuccess(res.data));
    else dispatch(UserActions.GetuserFail(res.data.response.data.message));
  });
};
export const login = (user, navigate) => async (dispatch) => {
  dispatch(UserActions.loginUserRequest());
  dispatch(
    axiosReq("post", process.env.REACT_APP_API + "/auth/admin/login", {}, user)
  ).then((res) => {
    if (res.success) {
      dispatch(UserActions.loginUserSuccess(res.data));
      dispatch(getUser());
      dispatch(
        axiosReq("patch", process.env.REACT_APP_API + "/user/me", {}, {})
      );
      dispatch(getUser());
      navigate("/");
    } else dispatch(UserActions.loginUserFail(res.data.response.data.message));
  });
};
export const logoutUser = (navigate) => async (dispatch) => {
  dispatch(UserActions.logoutUserRequest());
  dispatch(
    axiosReq(
      "patch",
      process.env.REACT_APP_API + "/user/me",
      {},
      { fcm_token: null }
    )
  ).then((res) => {
    dispatch(
      axiosReq("delete", process.env.REACT_APP_API + "/auth/logout", {})
    ).then((res) => {
      if (res.success) {
        dispatch(UserActions.GetuserSuccess(res.data));
        navigate("/session/signin");
      } else
        dispatch(UserActions.logoutUserFail(res.data.response.data.message));
    });
  });
};
export const editUser = (user) => async (dispatch) => {
  dispatch(UserActions.editUserRequest());
  dispatch(
    axiosReq("patch", process.env.REACT_APP_API + "/user/me", {}, user)
  ).then((res) => {
    if (res.success) {
      dispatch(UserActions.editUserSuccess(res.data));
      dispatch(getUser());
    } else dispatch(UserActions.editUserFail(res.data.response.data.message));
  });
};
export const editUserPassword = (data) => async (dispatch) => {
  dispatch(UserActions.changePasswordRequest());
  dispatch(
    axiosReq(
      "post",
      process.env.REACT_APP_API + "/user/changePassword",
      {},
      data
    )
  ).then((res) => {
    if (res.success) {
      dispatch(UserActions.changePasswordSuccess(res.data));
      dispatch(getUser());
    } else
      dispatch(UserActions.changePasswordFail(res.data.response.data.message));
  });
};
