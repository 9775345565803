import { axiosReq } from "../../app/utils/utils";
import { AdminSourcesActions } from "./SourcesReducers";

export const getSources = () => async (dispatch) => {
  const url = process.env.REACT_APP_API + "/referal";

  dispatch(AdminSourcesActions.getSourcesRequest());
  dispatch(axiosReq("get", url, {}, {})).then((res) => {
    if (res.success) {
      dispatch(AdminSourcesActions.getSourcesSuccess(res.data));
    } else {
      dispatch(
        AdminSourcesActions.getSourcesFail(res.data.response.data.message)
      );
    }
  });
};
export const addCategory = (data) => async (dispatch) => {
  const url = process.env.REACT_APP_API + "/referal/category";

  dispatch(AdminSourcesActions.addSourceCatRequest());
  dispatch(axiosReq("post", url, {}, data)).then((res) => {
    if (res.success) {
      dispatch(AdminSourcesActions.addSourceCatSuccess(res.data));
      dispatch(getSources());
    } else {
      dispatch(
        AdminSourcesActions.addSourceCatFail(res.data.response.data.message)
      );
    }
  });
};
export const addSource = (data,setAdd) => async (dispatch) => {
  const url = process.env.REACT_APP_API + "/referal/source";
  dispatch(AdminSourcesActions.addSourceRequest());
  dispatch(axiosReq("post", url, {}, data)).then((res) => {
    if (res.success) {
      dispatch(AdminSourcesActions.addSourceSuccess(res.data));
      dispatch(getSources());
      setAdd(false)
    } else {
      dispatch(
        AdminSourcesActions.addSourceFail(res.data.response.data.message)
      );
    }
  });
};

export const editSourceCatg = (id, data) => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/referal/category/${id}`;
  dispatch(AdminSourcesActions.editCategoryRequest());
  dispatch(axiosReq("patch", url, {}, data)).then((res) => {
    if (res.success) {
      dispatch(AdminSourcesActions.editCategorySuccess(res.data));
      dispatch(getSources());
    } else {
      dispatch(
        AdminSourcesActions.editCategoryError(res.data.response.data.message)
      );
    }
  });
};

export const editRefSource = (id, data) => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/referal/source/${id}`;
  dispatch(AdminSourcesActions.editSourceRequest());
  dispatch(axiosReq("patch", url, {}, data)).then((res) => {
    if (res.success) {
      dispatch(AdminSourcesActions.editSourceSuccess(res.data));
      dispatch(getSources());
    } else {
      dispatch(
        AdminSourcesActions.editSourceError(res.data.response.data.message)
      );
    }
  });
};
