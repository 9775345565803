/** @format */

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  NewsLetter: [],
  getNewsLetterLoading: false,
  getNewsLetterError: "",
  createNewsLetterLoading: false,
  createNewsLetterSuccess: false,
  createNewsLetterError: "",
  studentssubscribed: [],
  getstudentssubscribedLoading: false,
  getstudentssubscribedError: "",
};

const NewsLetterSlice = createSlice({
  name: "NewsLetter",
  initialState,
  reducers: {
    GetNewsLetterRequest(state) {
      state.getNewsLetterLoading = true;
      state.getNewsLetterError = "";
      state.NewsLetter = [];
    },
    GetNewsLetterSuccess(state, action) {
      state.getNewsLetterLoading = false;
      state.getNewsLetterError = "";
      state.NewsLetter = action.payload;
    },
    GetNewsLetterFail(state, action) {
      state.getNewsLetterLoading = false;
      state.getNewsLetterError = action.payload;
      state.NewsLetter = [];
    },
    createNewsLetterRequest(state) {
      state.createNewsLetterLoading = true;
      state.getNewsLetterError = "";
    },
    createNewsLetterSuccess(state, action) {
      state.createNewsLetterLoading = false;
      state.getNewsLetterError = "";
    },
    createNewsLetterFail(state, action) {
      state.createNewsLetterLoading = false;
      state.getNewsLetterError = action.payload;
    },
    getstudentssubscribedRequest(state) {
      state.getstudentssubscribedLoading = true;
      state.getstudentssubscribedError = "";
    },
    getstudentssubscribedSuccess(state, action) {
      state.getstudentssubscribedLoading = false;
      state.getstudentssubscribedError = "";
      state.studentssubscribed = action.payload;
    },
    getstudentssubscribedFail(state, action) {
      state.getstudentssubscribedLoading = false;
      state.getstudentssubscribedError = action.payload;
      state.studentssubscribed = [];
    },
    addindividualstudentRequest(state) {
      state.addindividualstudentLoading = true;
      state.addindividualstudentError = "";
    },
    addindividualstudentSuccess(state, action) {
      state.addindividualstudentLoading = false;
      state.addindividualstudentError = "";
    },
    addindividualstudentFail(state, action) {
      state.addindividualstudentLoading = false;
      state.addindividualstudentError = action.payload;
    },
  },
});

export const NewsLetterActions = NewsLetterSlice.actions;
export default NewsLetterSlice;
