/** @format */

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  Sources: [],
  getSourcesLoading: false,
  getSourcesSuccess: false,
  getSourcesFail: "",
  ////////Add Source////////
  addSourcesLoading: false,
  addSourcesSuccess: false,
  addSourcesFail: "",
  ////////Add Source Category////////
  addSourceCategoryLoading: false,
  addSourceCategorySuccess: false,
  addSourceCategoryFail: "",
  ////////Edit Category////////
  editCategoryLoading: false,
  editCategorySuccess: false,
  editCategoryError: "",
  ////////Edit Source////////
  editSourceLoading: false,
  editSourceSuccess: false,
  editSourceError: "",
};

const AdminSourcesSlice = createSlice({
  name: "AdminSources",
  initialState,
  reducers: {
    ////////Get Source////////
    getSourcesRequest(state) {
      state.getSourcesLoading = true;
    },
    getSourcesSuccess(state, action) {
      state.Sources = action.payload;
      state.getSourcesLoading = false;
      state.getSourcesSuccess = true;
      state.getSourcesFail = "";
    },
    getSourcesFail(state, action) {
      state.Sources = [];
      state.getSourcesLoading = false;
      state.getSourcesSuccess = false;
      state.getSourcesFail = action.payload;
    },
    ////////Add Source Catgegory////////
    // addSourceCategoryLoading: false,
    // addSourceCategorySuccess: false,
    // addSourceCategoryFail: "",
    addSourceCatRequest(state) {
      state.addSourceCategoryLoading = true;
    },
    addSourceCatSuccess(state) {
      state.addSourceCategoryLoading = false;
      state.addSourceCategorySuccess = true;
      state.addSourceCategoryFail = "";
    },
    addSourceCatFail(state, action) {
      state.addSourceCategoryLoading = false;
      state.addSourceCategorySuccess = false;
      state.addSourceCategoryFail = action.payload;
    },
    ////////Add Source////////
    // addSourcesLoading: false,
    // addSourcesSuccess: false,
    // addSourcesFail: "",
    addSourceRequest(state) {
      state.addSourcesLoading = true;
    },
    addSourceSuccess(state) {
      state.addSourcesLoading = false;
      state.addSourcesSuccess = true;
      state.addSourcesFail = "";
    },
    addSourceFail(state, action) {
      state.addSourcesLoading = false;
      state.addSourcesSuccess = false;
      state.addSourcesFail = action.payload;
    },
    ////////Edit Category////////
    editCategoryRequest(state) {
      state.editCategoryLoading = true;
    },
    editCategorySuccess(state) {
      state.editCategoryLoading = false;
      state.editCategorySuccess = true;
      state.editCategoryError = "";
    },
    editCategoryError(state, action) {
      state.editCategoryLoading = false;
      state.editCategorySuccess = false;
      state.editCategoryError = action.payload;
    },

    ////////Edit Source////////
    editSourceRequest(state) {
      state.editSourceLoading = true;
      state.editSourceError = "";
      state.editSourceLoading = false;

    }
    ,
    editSourceSuccess(state) {
      state.editSourceLoading = false;
      state.editSourceSuccess = true;
      state.editSourceError = "";
    }
    ,
    editSourceError(state, action) {
      state.editSourceLoading = false;
      state.editSourceSuccess = false;
      state.editSourceError = action.payload;
    }

  },
});

export const AdminSourcesActions = AdminSourcesSlice.actions;
export default AdminSourcesSlice;
