import {
  Autocomplete,
  Button,
  Icon,
  IconButton,
  TextField,
  useIsFocusVisible,
} from "@mui/material";
import {
  addFaculty,
  addMajorDegree,
  editFaculty,
  editMajorDegree,
  getDegree,
  getMajorDegree,
  getMajors,
} from "Redux/Universities/UniversitiesActions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PopUpDel from "Components/DeletePop/PopUpDel";
import { getAllMajorGroups } from "Redux/MajorCategories/MajorCategoriesActions";
import { get_Degrees } from "Redux/Degrees/DegreesActions";

function EditMajorDegree({ oldFaculties }) {
  const {
    Majors,
    Degrees,
    addMajorDegreeLoading,
    addMajorDegreeError,
    addMajorDegreeSuccess,
    editMajorDegreeLoading,
    editMajorDegreeError,
    editMajorDegreeSuccess,
  } = useSelector((state) => state.UniversitiesReducer);
  const { allmajorcategories } = useSelector((state) => state.MajorCategories);
  const { degrees } = useSelector((state) => state.degreeReducer);
  const dispatch = useDispatch();
  const [add, setAdd] = useState(false);
  const [error, setError] = useState("");
  const [edit, setEdit] = useState({});
  const [deleteP, setDeleteP] = useState(false);
  const [selectedFac, setSelectedFac] = useState(null);
  const [selectedMaj, setSelectedMaj] = useState(null);
  const [newMajDegree, setNewMajDegree] = useState({});
  const [clicked, setClicked] = useState(false);
  const handleSave = (deg) => {
    if (
      !edit.degree ||
      !edit.tuition ||
      !edit.cost ||
      !edit.duration ||
      !edit.intakes ||
      !edit.visaReq ||
      !edit.entryReq ||
      !edit.description
    ) {
      setError("Please fill all the fields");
    } else {
      setEdit({ step: -1 });
      setError("");
      const visa = edit.visaReq.split(",");
      const entry = edit.entryReq.split(",");
      const newMajDeg = {
        major: selectedMaj.id,
        degree: edit.degree.id,
        tuition: parseInt(edit.tuition),
        about: edit.description,
        living_cost: parseInt(edit.cost),
        visa_requirements: visa,
        duration: parseInt(edit.duration),
        entry_requirements: entry,
        intakes: parseInt(edit.intakes),
      };
      dispatch(editMajorDegree(edit.id, newMajDeg, selectedMaj.id));
    }
  };

  const handleAdd = () => {
    if (
      !newMajDegree.degree ||
      !newMajDegree.tuition ||
      !newMajDegree.cost ||
      !newMajDegree.duration ||
      !newMajDegree.intakes ||
      !newMajDegree.visaReq ||
      !newMajDegree.entryReq ||
      !newMajDegree.description
    ) {
      setError("Please fill all the fields");
    } else {
      setClicked(true);
      setError("");
      const visa = newMajDegree.visaReq.split(",");
      const entry = newMajDegree.entryReq.split(",");
      const newMajDeg = {
        major: selectedMaj.id,
        degree: newMajDegree.degree.id,
        tuition: parseInt(newMajDegree.tuition),
        about: newMajDegree.description,
        living_cost: parseInt(newMajDegree.cost),
        visa_requirements: visa,
        duration: parseInt(newMajDegree.duration),
        entry_requirements: entry,
        intakes: parseInt(newMajDegree.intakes),
      };
      dispatch(addMajorDegree(newMajDeg, selectedMaj.id));
    }
  };

  const handleDelete = () => {
    setDeleteP(false);
  };

  useEffect(() => {
    if (selectedFac?.id) {
      setSelectedMaj(null);
      dispatch(getMajors(selectedFac.id));
    }
  }, [selectedFac]);

  useEffect(() => {
    if (selectedMaj?.id) {
      dispatch(getDegree(selectedMaj.id));
    }
  }, [selectedMaj]);
  useEffect(() => {
    dispatch(get_Degrees());
  }, []);

  useEffect(() => {
    if (addMajorDegreeError) {
      setError(addMajorDegreeError);
    } else if (editMajorDegreeError) {
      setError(editMajorDegreeError);
    }
  }, [addMajorDegreeError, editMajorDegreeError]);

  useEffect(() => {
    if (addMajorDegreeSuccess && clicked) {
      setAdd(false);
      setNewMajDegree({});
      setClicked(false);
    }
  }, [addMajorDegreeSuccess]);

  return (
    <div className="add_faculty_main_div">
      <div className="add_facutly_header">
        <h3>Edit Major Degrees</h3>
      </div>

      <div className="add_major_body_div2">
        <div className="add_major_body_div">
          <Autocomplete
            style={{ width: "30%" }}
            options={oldFaculties}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose Faculty"
                variant="outlined"
                fullWidth
              />
            )}
            value={selectedFac}
            onChange={(e, value) => {
              setSelectedFac(value);
            }}
          />
          <Autocomplete
            style={{ width: "30%" }}
            options={Majors}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose Major"
                variant="outlined"
                fullWidth
              />
            )}
            value={selectedMaj}
            onChange={(e, value) => {
              setSelectedMaj(value);
            }}
          />
          <Button
            variant="contained"
            onClick={() => {
              setAdd(true);
              setEdit({});
            }}
            disabled={selectedFac?.id && selectedMaj?.id ? false : true}
          >
            Add Major Degree
          </Button>
        </div>
        {Degrees?.map((deg, index) => {
          return (
            <ul>
              <li>
                <div className="add_major_body_div2_li_div">
                  <div style={{ minWidth: "8rem" }}>{deg.name}</div>
                  <IconButton>
                    <Icon
                      color="primary"
                      onClick={() => {
                        setAdd(false);
                        setEdit({
                          step: index,
                          id: deg.major_degrees[0].id,
                          degree: deg,
                          tuition: deg?.major_degrees[0]?.tuition,
                          cost: deg?.major_degrees[0]?.living_cost,
                          duration: deg?.major_degrees[0]?.duration,
                          intakes: deg?.major_degrees[0]?.intakes,
                          visaReq:
                            deg?.major_degrees[0]?.visa_requirements?.join(","),
                          entryReq:
                            deg?.major_degrees[0]?.entry_requirements?.join(
                              ","
                            ),
                          description: deg?.major_degrees[0]?.about,
                        });
                      }}
                    >
                      edit
                    </Icon>
                  </IconButton>
                </div>
                {edit.step === index && (
                  <div className="add_major_body_div">
                    <Autocomplete
                      style={{ width: "30%" }}
                      options={degrees}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Degree"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                      value={edit?.degree}
                      onChange={(e, value) => {
                        setEdit({ ...edit, degree: value });
                      }}
                    />
                    <TextField
                      style={{ width: "30%" }}
                      label="Yearly Tution Fees"
                      type="number"
                      inputProps={{ min: 0 }}
                      value={edit?.tuition}
                      onChange={(e) =>
                        setEdit({ ...edit, tuition: e.target.value })
                      }
                    />
                    <TextField
                      style={{ width: "30%" }}
                      label="Yearly Living Cost"
                      type="number"
                      inputProps={{ min: 0 }}
                      value={edit?.cost}
                      onChange={(e) =>
                        setEdit({ ...edit, cost: e.target.value })
                      }
                    />
                    <TextField
                      style={{ width: "30%" }}
                      label="Yearly Duration"
                      type="number"
                      inputProps={{ min: 0 }}
                      value={edit?.duration}
                      onChange={(e) =>
                        setEdit({ ...edit, duration: e.target.value })
                      }
                    />
                    <TextField
                      style={{ width: "30%" }}
                      label="Yearly Intakes"
                      type="number"
                      inputProps={{ min: 0 }}
                      value={edit?.intakes}
                      onChange={(e) =>
                        setEdit({ ...edit, intakes: e.target.value })
                      }
                    />
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                        marginTop: "-1rem",
                      }}
                    >
                      <h4>Visa Requirements: </h4>

                      <textarea
                        className="text_area_uni"
                        placeholder="Visa requirements seperated by comma e.g: IELTS,TOEFL"
                        value={edit?.visaReq}
                        onChange={(e) =>
                          setEdit({ ...edit, visaReq: e.target.value })
                        }
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                        marginTop: "-1rem",
                      }}
                    >
                      <h4>Entry Requirements: </h4>
                      <textarea
                        className="text_area_uni"
                        placeholder="Entry requirements seperated by comma e.g: IELTS,TOEFL"
                        value={edit?.entryReq}
                        onChange={(e) =>
                          setEdit({ ...edit, entryReq: e.target.value })
                        }
                      />
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "10px",
                        marginTop: "-1rem",
                      }}
                    >
                      <h4>Major Degree Description: </h4>
                      <textarea
                        className="text_area_uni"
                        placeholder="Major Degree Description"
                        value={edit?.description}
                        onChange={(e) =>
                          setEdit({
                            ...edit,
                            description: e.target.value,
                          })
                        }
                      />
                    </div>
                    {error && <div className="error_div">{error}</div>}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "98%",
                        gap: "1rem",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => handleSave()}
                        disabled={
                          editMajorDegreeLoading || addMajorDegreeLoading
                        }
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => setEdit({})}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                )}
              </li>
            </ul>
          );
        })}
      </div>
      {deleteP && (
        <PopUpDel setopen={setDeleteP} open={deleteP} func={handleDelete} />
      )}
      <div className="edit_faculty_body">
        {add && (
          <div className="add_major_body_div">
            <Autocomplete
              style={{ width: "30%" }}
              options={degrees}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Degree"
                  variant="outlined"
                  fullWidth
                />
              )}
              value={newMajDegree?.degree}
              onChange={(e, value) => {
                setNewMajDegree({ ...newMajDegree, degree: value });
              }}
            />
            <TextField
              style={{ width: "30%" }}
              label="Yearly Tution Fees"
              type="number"
              inputProps={{ min: 0 }}
              value={newMajDegree?.tuition}
              onChange={(e) =>
                setNewMajDegree({ ...newMajDegree, tuition: e.target.value })
              }
            />
            <TextField
              style={{ width: "30%" }}
              label="Yearly Living Cost"
              type="number"
              inputProps={{ min: 0 }}
              value={newMajDegree?.cost}
              onChange={(e) =>
                setNewMajDegree({ ...newMajDegree, cost: e.target.value })
              }
            />
            <TextField
              style={{ width: "30%" }}
              label="Yearly Duration"
              type="number"
              inputProps={{ min: 0 }}
              value={newMajDegree?.duration}
              onChange={(e) =>
                setNewMajDegree({ ...newMajDegree, duration: e.target.value })
              }
            />
            <TextField
              style={{ width: "30%" }}
              label="Yearly Intakes"
              type="number"
              inputProps={{ min: 0 }}
              value={newMajDegree?.intakes}
              onChange={(e) =>
                setNewMajDegree({ ...newMajDegree, intakes: e.target.value })
              }
            />
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
                marginTop: "-1rem",
              }}
            >
              <h4>Visa Requirements: </h4>

              <textarea
                className="text_area_uni"
                placeholder="Visa requirements seperated by comma e.g: IELTS,TOEFL"
                value={newMajDegree?.visaReq}
                onChange={(e) =>
                  setNewMajDegree({ ...newMajDegree, visaReq: e.target.value })
                }
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
                marginTop: "-1rem",
              }}
            >
              <h4>Entry Requirements: </h4>
              <textarea
                className="text_area_uni"
                placeholder="Entry requirements seperated by comma e.g: IELTS,TOEFL"
                x
                value={newMajDegree?.entryReq}
                onChange={(e) =>
                  setNewMajDegree({ ...newMajDegree, entryReq: e.target.value })
                }
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: "10px",
                marginTop: "-1rem",
              }}
            >
              <h4>Major Degree Description: </h4>
              <textarea
                className="text_area_uni"
                placeholder="Major Degree Description"
                value={newMajDegree?.description}
                onChange={(e) =>
                  setNewMajDegree({
                    ...newMajDegree,
                    description: e.target.value,
                  })
                }
              />
            </div>
            {error && <div className="error_div">{error}</div>}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "98%",
                gap: "1rem",
              }}
            >
              <Button
                variant="contained"
                onClick={() => handleAdd()}
                disabled={editMajorDegreeLoading || addMajorDegreeLoading}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => setAdd(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default EditMajorDegree;
