import { axiosReq } from "app/utils/utils";
import { NewsLetterActions } from "./NewsLetterReducers";

export const GetNewsLetter = (limit, offset) => async (dispatch) => {
  const url = process.env.REACT_APP_API + "/admin/newsletters";

  dispatch(NewsLetterActions.GetNewsLetterRequest());
  dispatch(axiosReq("get", url, {}, {}, { limit: limit, offset: offset })).then(
    (res) => {
      if (res.success)
        dispatch(NewsLetterActions.GetNewsLetterSuccess(res.data));
      else
        dispatch(
          NewsLetterActions.GetNewsLetterFail(res.data.response.data.message)
        );
    }
  );
};

export const AddNewsLetter = (data) => async (dispatch) => {
  dispatch(NewsLetterActions.createNewsLetterRequest());
  dispatch(
    axiosReq("post", process.env.REACT_APP_API + "/admin/newsletter", {}, data)
  ).then((res) => {
    if (res.success) {
      dispatch(NewsLetterActions.createNewsLetterSuccess(res.data));
      dispatch(GetNewsLetter(10, 0));
    } else {
      dispatch(
        NewsLetterActions.createNewsLetterFail(res.data.response.data.message)
      );
    }
  });
};

export const getstudentssubsc = () => async (dispatch) => {
  dispatch(NewsLetterActions.getstudentssubscribedSuccess());
  dispatch(
    axiosReq(
      "get",
      process.env.REACT_APP_API + "/user/subscribed/emails",
      {},
      {}
    )
  ).then((res) => {
    if (res.success) {
      dispatch(NewsLetterActions.getstudentssubscribedSuccess(res.data));
    } else {
      dispatch(
        NewsLetterActions.getstudentssubscribedFail(
          res.data.response.data.message
        )
      );
    }
  });
};
export const sendindividualmail = (data) => async (dispatch) => {
  dispatch(NewsLetterActions.addindividualstudentRequest());
  dispatch(
    axiosReq("post", process.env.REACT_APP_API + "/admin/email", {}, data)
  ).then((res) => {
    if (res.success) {
      dispatch(NewsLetterActions.addindividualstudentSuccess(res.data));
    } else {
      dispatch(
        NewsLetterActions.addindividualstudentFail(
          res.data.response.data.message
        )
      );
    }
  });
};
