import React, { useEffect, useState } from "react";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  Dialog,
  Autocomplete,
} from "@mui/material";
import "./SourcesTable.css";
import {
  getSources,
  addCategory,
  addSource,
  editSourceCatg,
  editRefSource,
} from "../../Redux/Sources/SourcesActions";
import { useDispatch, useSelector } from "react-redux";
import Loading from "app/components/MatxLoading";
import { set } from "lodash";
function SourcesTable() {
  const {
    Sources,
    addSourcesSuccess,
    getSourcesLoading,
    addSourcesLoading,
    addSourceCategoryLoading,
    addSourcesFail,
    addSourceCategoryFail,
    addSourceCategorySuccess,
    editCategoryLoading,
    editCategorySuccess,
    editCategoryError,
    editSourceLoading,
    editSourceSuccess,
    editSourceError,
  } = useSelector((state) => state.SourcesReducers);
  const dispatch = useDispatch();
  const [openAddSource, setOpenAddSource] = useState(false);
  const [openAddCategory, setOpenAddCategory] = useState(false);
  const [addCategorySource, setAddCategorySource] = useState("");
  const [addedSource, setAddedSource] = useState("");
  const [addedCategory, setAddedCategory] = useState(null);
  const [openEditCatg, setOpenEditCatg] = useState(false);
  const [error, setError] = useState("");
  const [openEditRef, setOpenEditRef] = useState(false);
  const [catgId, setCatgId] = useState(null);
  const [sourceId, setSourceId] = useState(null);
  const [newCatg, setNewCatg] = useState("");
  const [newSource, setNewSource] = useState("");
  const [error2, setError2] = useState("");
  const [clicked, setClicked] = useState(false);
  const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
      "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
      "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
  }));
  useEffect(() => {
    dispatch(getSources());
  }, []);
  const arr2 = [];
  // Find the maximum number of sources among all categories
  const maxSources = Math.max(
    ...Sources.map((category) => category.sources.length)
  );

  // Iterate over each source index
  for (let sourceIndex = 0; sourceIndex < maxSources; sourceIndex++) {
    // Create a new row to store the current sources
    const newRow = [];

    // Iterate over each category
    for (
      let categoryIndex = 0;
      categoryIndex < Sources.length;
      categoryIndex++
    ) {
      const category = Sources[categoryIndex];
      const sources = category.sources;

      // If a source exists at the current index, add its name to the row
      if (sourceIndex < sources.length) {
        const source = sources[sourceIndex];
        newRow.push(source.name);
      } else {
        newRow.push(""); // Push an empty string if there is no source
      }
    }

    // Push the row to the transformed array
    arr2.push(newRow);
  }
  const handleAddSourceCat = () => {
    const data = {
      name: addCategorySource,
    };
    dispatch(addCategory(data));
  };
  const handleAddSource = () => {
    const data = {
      name: addedSource,
      referal_category: addedCategory.id,
    };
    dispatch(addSource(data, setOpenAddSource));
    setAddedSource("");
  };

  const handleEditCatg = () => {
    const data = {
      name: newCatg,
    };
    setClicked(true);
    dispatch(editSourceCatg(catgId.id, data));
  };

  const handleEditRef = () => {
    const data = {
      name: newSource,
    };
    setClicked(true);
    dispatch(editRefSource(sourceId.id, data));
  };
  // Add Source handeling
  useEffect(() => {
    if (addSourcesFail) {
      setError(addSourcesFail);
      setOpenAddSource(true);
    }
    if (addSourcesSuccess && !addSourcesFail) {
      setAddedSource("");
      setOpenAddSource(false);
    }
  }, [addSourcesFail, addSourcesSuccess]);
  // Add Source Category Handeling
  useEffect(() => {
    if (addSourceCategoryFail) {
      setError("");
      setError(addSourceCategoryFail);
      setOpenAddCategory(true);
    }
    if (addSourceCategorySuccess && !addSourceCategoryFail) {
      setAddCategorySource("");
      setOpenAddCategory(false);
      setError("");
    }
  }, [addSourceCategoryFail]);

  useEffect(() => {
    if (editCategoryError) {
      setError2(editCategoryError);
    } else {
      setError2("");
    }
    if (editSourceError) {
      setError2(editSourceError);
    } else {
      setError2("");
    }
  }, [editCategoryError, editSourceError]);

  useEffect(() => {
    if (editCategorySuccess && clicked) {
      setOpenEditCatg(false);
      setClicked(false);
    }
  }, [editCategorySuccess, clicked]);

  useEffect(() => {
    if (editSourceSuccess && clicked) {
      setOpenEditRef(false);
      setClicked(false);
    }
  }, [editSourceSuccess, clicked]);
  return (
    <>
      {getSourcesLoading || addSourcesLoading || addSourceCategoryLoading ? (
        <Loading />
      ) : (
        <Box width="95%" overflow="auto" margin="auto" marginTop="2rem">
          <div className="SourcesDiv">
            <h1>Sources Table</h1>
            <div style={{ display: "flex", gap: "1rem" }}>
              {" "}
              <Button
                variant="contained"
                onClick={() => {
                  setOpenEditRef(true);
                  setError("");
                  setSourceId(null);
                  setNewSource("");
                }}
              >
                Edit Referral Source
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setOpenEditCatg(true);
                  setError("");
                  setCatgId(null);
                  setNewCatg("");
                }}
              >
                Edit Source Category
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setOpenAddCategory(true);
                  setError("");
                }}
              >
                Add Source Category
              </Button>
            </div>
          </div>
          <StyledTable>
            <TableHead>
              <TableRow>
                {Sources?.map((header) => {
                  return (
                    <TableCell className="thNameImgDiv">
                      {header.name}
                      <IconButton
                        onClick={() => {
                          setOpenAddSource(true);
                          setAddedCategory({
                            id: header.id,
                            name: header.name,
                          });
                        }}
                      >
                        <Icon>add</Icon>
                      </IconButton>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {arr2?.map((item, index) => {
                return (
                  <TableRow key={index}>
                    {item.map((singleItem, i) => {
                      return (
                        <TableCell className={!singleItem && "SSourcesEmptyTd"}>
                          {singleItem}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </StyledTable>
          {openAddCategory && (
            <Dialog
              onClose={() => {
                setOpenAddCategory(false);
                setAddCategorySource("");
                setError("");
              }}
              aria-labelledby="customized-dialog-title"
              open={openAddCategory}
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={() => {
                  setOpenAddCategory(false);
                }}
              >
                Add Category
              </DialogTitle>
              <DialogContent dividers>
                <div className="add_cat_div">
                  <TextField
                    className="add_category_input"
                    label="Source Categorie"
                    value={addCategorySource}
                    onChange={(e) => setAddCategorySource(e.target.value)}
                  />
                </div>
                <div className="AddCategoryError">{error}</div>
              </DialogContent>
              <DialogActions dividers>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    handleAddSourceCat();
                  }}
                  disabled={addCategorySource === "" ? true : false}
                >
                  Save changes
                </Button>
                <Button
                  onClick={() => {
                    setOpenAddCategory(false);
                    setAddCategorySource("");
                  }}
                  variant="contained"
                  color="secondary"
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {openAddSource && (
            <Dialog
              onClose={() => {
                setOpenAddSource(false);
                setAddedSource("");
              }}
              aria-labelledby="customized-dialog-title"
              open={openAddSource}
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={() => {
                  setOpenAddSource(false);
                }}
              >
                Add Source
              </DialogTitle>
              <DialogContent dividers>
                <div className="addCatInputDiv">
                  <div className="source_textField">
                    <Autocomplete
                      options={Sources}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Source"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                      value={addedCategory}
                      onChange={(newValue, value) => {
                        setAddedCategory(value);
                      }}
                    />
                    <TextField
                      style={{ width: "100%" }}
                      label="Source"
                      value={addedSource}
                      onChange={(e) => setAddedSource(e.target.value)}
                    />
                  </div>
                  <div className="AddCategoryError">{error}</div>
                </div>
              </DialogContent>
              <DialogActions dividers>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleAddSource();
                  }}
                  disabled={addedSource === "" ? true : false}
                >
                  Save changes
                </Button>
                <Button
                  onClick={() => {
                    setOpenAddSource(false);
                    setError("");
                  }}
                  variant="contained"
                  color="secondary"
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          )}

          {openEditCatg && (
            <Dialog
              onClose={() => {
                setOpenEditCatg(false);
                setCatgId(null);
                setNewCatg("");
              }}
              aria-labelledby="customized-dialog-title"
              open={openEditCatg}
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={() => {
                  setOpenAddSource(false);
                }}
              >
                Edit Source
              </DialogTitle>
              <DialogContent dividers>
                <div className="addCatInputDiv">
                  <div className="source_textField">
                    <Autocomplete
                      options={Sources}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Choose Source"
                          variant="outlined"
                        />
                      )}
                      value={catgId}
                      onChange={(newValue, value) => {
                        setCatgId(value);
                      }}
                    />
                    <TextField
                      style={{ width: "100%" }}
                      label="New Name"
                      value={newCatg}
                      onChange={(e) => setNewCatg(e.target.value)}
                    />
                  </div>
                  <div className="AddCategoryError">{error2}</div>
                </div>
              </DialogContent>
              <DialogActions dividers>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleEditCatg();
                  }}
                  disabled={newCatg === "" ? true : false}
                >
                  Save changes
                </Button>
                <Button
                  onClick={() => {
                    setOpenEditCatg(false);
                    setError("");
                  }}
                  variant="contained"
                  color="secondary"
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {openEditRef && (
            <Dialog
              onClose={() => {
                setOpenEditRef(false);
                setAddedSource("");
              }}
              aria-labelledby="customized-dialog-title"
              open={openEditRef}
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={() => {
                  setOpenAddSource(false);
                }}
              >
                Edit Referral Source
              </DialogTitle>
              <DialogContent dividers>
                <div className="addCatInputDiv">
                  <div className="source_textField">
                    <div
                      style={{ display: "flex", gap: "1rem", width: "100%" }}
                    >
                      <div className="sourceDopdown">
                        <Autocomplete
                          // style={{ width: "100%" }}
                          options={Sources}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Choose Source Catgeory"
                              variant="outlined"
                            />
                          )}
                          value={catgId}
                          onChange={(newValue, value) => {
                            setCatgId(value);
                          }}
                        />
                      </div>
                      {catgId?.sources && (
                        <Autocomplete
                          style={{ width: "100%" }}
                          options={catgId.sources}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Choose Referral Source"
                              variant="outlined"
                            />
                          )}
                          value={sourceId}
                          onChange={(newValue, value) => {
                            setSourceId(value);
                          }}
                        />
                      )}
                    </div>
                    <TextField
                      style={{ width: "100%" }}
                      label="New Name"
                      value={newSource}
                      onChange={(e) => setNewSource(e.target.value)}
                    />
                  </div>
                  <div className="AddCategoryError">{error2}</div>
                </div>
              </DialogContent>
              <DialogActions dividers>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleEditRef();
                  }}
                  disabled={newSource === "" ? true : false}
                >
                  Save changes
                </Button>
                <Button
                  onClick={() => {
                    setOpenEditRef(false);
                    setError("");
                  }}
                  variant="contained"
                  color="secondary"
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Box>
      )}
    </>
  );
}

export default SourcesTable;
