import MajorCategories from "Pages/MajorCategories/MajorCategories";
import Degrees from "Pages/Degrees/Degrees";
import Loadable from "app/components/Loadable";
import { lazy } from "react";
import Providers from "Pages/Providers/Providers";
import Universities from "Pages/Universities/Universities";
import UniversityView from "Pages/Universities/UniveristyAct/UniversityView";
import Offices from "Pages/Offices/Offices";
import Sources from "Pages/Sources/Sources";
import Profile from "Pages/Profile/Profile";
import UniveristyAdd from "Pages/Universities/UniveristyAct/AddUniversity/UniveristyAdd";
import EditUniversity from "Pages/Universities/UniveristyAct/EditUniversity/EditUniversity";
import NewsLetter from "Pages/NewsLetters/NewsLetters";

const Analytics = Loadable(lazy(() => import("../Pages/Home/Analytics")));
const Students = Loadable(lazy(() => import("../Pages/Students/Students")));
const Locations = Loadable(lazy(() => import("../Pages/Locations/Locations")));
const Sales = Loadable(lazy(() => import("../Pages/Sales/Sales")));
const Crm = Loadable(lazy(() => import("../Pages/Crm/Crm")));

const Counselors = Loadable(
  lazy(() => import("../Pages/Counselors/Counselors"))
);

const dashboardRoutes = [
  { path: "/dashboard/default", element: <Analytics /> },
  { path: "/dashboard/students", element: <Students /> },
  { path: "/dashboard/counselors", element: <Counselors /> },
  { path: "/dashboard/sales", element: <Sales /> },

  {
    path: "/dashboard/major_groups",
    element: <MajorCategories />,
  },
  {
    path: "/dashboard/universities",
    element: <Universities />,
  },
  {
    path: "/dashboard/universities/add",
    element: <UniveristyAdd />,
  },
  {
    path: "/dashboard/universities/singleUni/:id",
    element: <UniversityView />,
  },
  {
    path: "/dashboard/universities/edit/:id",
    element: <EditUniversity />,
  },
  {
    path: "/dashboard/crm",
    element: <Crm />,
  },
  { path: "/dashboard/degrees", element: <Degrees /> },
  { path: "/dashboard/locations", element: <Locations /> },
  { path: "/dashboard/providers", element: <Providers /> },
  { path: "/dashboard/offices", element: <Offices /> },
  { path: "/dashboard/sources", element: <Sources /> },
  { path: "/dashboard/profile", element: <Profile /> },
  { path: "/dashboard/newsletter", element: <NewsLetter /> },
  {},
];

export default dashboardRoutes;
