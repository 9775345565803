/** @format */

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  Sales: [],
  getSalesLoading: false,
  getSalesError: "",
  addSalesLoading: false,
  addSalesSuccess: false,
  addSalesError: "",
  Countries: [],
  getCountriesLoading: false,
  getCountriesError: "",
  getofficesLoading: false,

  getofficesError: "",
  Offices: [],
  editSalesLoading: false,
  editSalesSuccess: false,
  editSalesError: "",

  deletesalesLoading: false,
  deletesalesError: "",
};

const SalesSlice = createSlice({
  name: "Sales",
  initialState,
  reducers: {
    GetSalesRequest(state) {
      state.getSalesLoading = true;
      state.getSalesError = "";
      state.Sales = [];
    },
    GetSalesSuccess(state, action) {
      state.getSalesLoading = false;
      state.getSalesError = "";
      state.Sales = action.payload;
    },
    GetSalesFail(state, action) {
      state.getSalesLoading = false;
      state.getSalesError = action.payload;
      state.Sales = [];
    },
    AddSalesRequest(state) {
      state.addSalesLoading = true;
      state.addSalesError = "";
      state.addSalesSuccess = false;
    },
    AddSalesSuccess(state, action) {
      state.addSalesLoading = false;
      state.addSalesError = "";
      state.addSalesSuccess = true;
    },
    AddSalesFail(state, action) {
      state.addSalesLoading = false;
      state.addSalesError = action.payload;
      state.addSalesSuccess = false;
    },
    getcountriesRequest(state) {
      state.getCountriesLoading = true;
      state.getCountriesError = "";
    },
    getcountriesSuccess(state, action) {
      state.getCountriesLoading = false;
      state.getCountriesError = "";
      state.Countries = action.payload;
    },
    getcountriesFail(state, action) {
      state.getCountriesLoading = false;
      state.getCountriesError = action.payload;
      state.Countries = [];
    },
    getofficesRequest(state) {
      state.getofficesLoading = true;
      state.getofficesError = "";
    },
    getofficesSuccess(state, action) {
      state.getofficesLoading = false;
      state.getofficesError = "";
      state.Offices = action.payload;
    },
    getofficesFail(state, action) {
      state.getofficesLoading = false;
      state.getofficesError = action.payload;
      state.Offices = [];
    },
    editSalesRequest(state) {
      state.editSalesLoading = true;
      state.editcounselorError = "";
      state.editSalesSuccess = false;
    },
    editSalesSuccess(state, action) {
      state.editSalesLoading = false;
      state.editSalesError = "";
      state.editSalesSuccess = true;
    },
    editSalesFail(state, action) {
      state.editSalesLoading = false;
      state.editSalesError = action.payload;
      state.editSalesSuccess = false;
    },
    deletesalesRequest(state) {
      state.deletesalesLoading = true;
      state.deletesalesError = "";
    },
    deletesalesSuccess(state, action) {
      state.deletesalesLoading = false;
      state.deletesalesError = "";
    },
    deletesalesFail(state, action) {
      state.deletesalesLoading = false;
      state.deletesalesError = action.payload;
    },
  },
});

export const SalesActions = SalesSlice.actions;
export default SalesSlice;
