import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, FormControlLabel, Icon, Switch } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogActions from "@mui/material/DialogActions";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const DialogTitleRoot = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
  "& .closeButton": {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogTitle = (props) => {
  const { children, onClose } = props;
  return (
    <DialogTitleRoot disableTypography>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className="closeButton"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitleRoot>
  );
};

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  "&.root": { padding: theme.spacing(2) },
}));

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  "&.root": { margin: 0, padding: theme.spacing(1) },
}));

function ViewProvidersPopup({ provider, country, city }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const { editProviderFail } = useSelector((state) => state.providersReducer);

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <Icon>visibility</Icon>
      </IconButton>

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        fullWidth={"sm"}
        maxWidth={"sm"}
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Provider view
        </DialogTitle>

        <DialogContent dividers>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: "0.5rem",
            }}
          >
            <div
              style={{
                paddingBottom: "1rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: "0.5rem",
              }}
            >
              <Typography display={"flex"} gap={"0.5rem"}>
                <b>Name: </b>
                {provider.provider}
              </Typography>
              <Typography display={"flex"} gap={"0.5rem"}>
                <b>Institution: </b>
                {provider.institution}
              </Typography>
              <Typography display={"flex"} gap={"0.5rem"}>
                <b>Country: </b>
                {country}
              </Typography>

              <Typography display={"flex"} gap={"0.5rem"}>
                <b>Commission: </b>
                {provider.commission}
              </Typography>
            </div>
            <div
              style={{
                paddingBottom: "1rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: "0.5rem",
              }}
            >
              <Typography display={"flex"} gap={"0.5rem"}>
                <b>City: </b>
                {city}
              </Typography>

              <Typography display={"flex"} gap={"0.5rem"}>
                <b>Contact: </b>
                {provider.contact}
              </Typography>
              <Typography display={"flex"} gap={"0.5rem"}>
                <b>Link: </b>
                {provider.search_tool}
              </Typography>
            </div>
          </div>
          <Typography display={"flex"} gap={"0.5rem"}>
            <b>Procedure:</b>
            {provider.application_procedure}
          </Typography>
          <div
            style={{
              paddingTop: "1rem",
              width: "78%",
              display: "flex",
              justifyContent: "space-between",
              gap: "0.5rem",
            }}
          >
            <FormControlLabel
              value="start"
              control={
                <Switch
                  value={provider.medecine}
                  checked={provider.medecine}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  style={{ cursor: "default" }}
                />
              }
              label="Medicine"
              labelPlacement="start"
            />
            <FormControlLabel
              value="start"
              control={
                <Switch
                  value={provider.online_program}
                  checked={provider.online_program}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  style={{ cursor: "default" }}
                />
              }
              label="Online Program"
              labelPlacement="start"
            />
          </div>
          {editProviderFail && (
            <Typography gutterBottom style={{ color: "red" }}>
              {editProviderFail}
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              handleClose();
            }}
            color="warning"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ViewProvidersPopup;
