import { axiosReq } from "app/utils/utils";
import { MajorCategoriesActions } from "./MajorCategoriesReducers";

export const getAllMajorGroups = () => async (dispatch) => {
  dispatch(MajorCategoriesActions.getallmajorcategoriesRequest());
  dispatch(
    axiosReq("get", process.env.REACT_APP_API + "/major/group", {}, {})
  ).then((res) => {
    if (res.success)
      dispatch(MajorCategoriesActions.getallmajorcategoriesSuccess(res.data));
    else
      dispatch(
        MajorCategoriesActions.getallmajorcategoriesFail(
          res.data.response.data.message
        )
      );
  });
};

export const addMajorGroup = (major) => async (dispatch) => {
  dispatch(MajorCategoriesActions.addmajorcategoryRequest());
  dispatch(
    axiosReq("post", process.env.REACT_APP_API + "/major/group", {}, major)
  ).then((res) => {
    if (res.success) {
      dispatch(MajorCategoriesActions.addmajorcategorySuccess(res.data));
      dispatch(getAllMajorGroups());
    } else
      dispatch(
        MajorCategoriesActions.addmajorcategoryFail(
          res.data.response.data.message
        )
      );
  });
};

export const editMajorGroup = (id, major) => async (dispatch) => {
  dispatch(MajorCategoriesActions.editmajorcategoryRequest());
  dispatch(
    axiosReq(
      "patch",
      process.env.REACT_APP_API + `/major/group/${id}`,
      {},
      major
    )
  ).then((res) => {
    if (res.success) {
      dispatch(MajorCategoriesActions.editmajorcategorySuccess(res.data));
      dispatch(getAllMajorGroups());
    } else
      dispatch(
        MajorCategoriesActions.editmajorcategoryFail(
          res.data.response.data.message
        )
      );
  });
};
