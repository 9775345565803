import React from "react";
import "./Providers.css";
import { Button } from "@mui/material";
import ProvidersTable from "./ProvidersTable";
import AddProvidersPopup from "./AddProvidersPopup";

function Providers() {
  return (
    <div className="providers_main">
      <div className="providers_top">
        <h1 className="providers_top_title">Providers Table</h1>
        <AddProvidersPopup />
      </div>
      <ProvidersTable />
    </div>
  );
}

export default Providers;
