import React from "react";
import "./Degrees.css";
import { Button } from "@mui/material";
import DegreeTable from "./DegreeTable";
import AddDegreePopup from "./AddDegreePopup";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "Redux/User/UserActions";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function Degrees() {
  const role = useSelector((state) => state.User?.user?.role);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (role && role !== "admin") {
      dispatch(logoutUser(navigate));
    }
  }, [role]);
  return (
    <div className="degrees_main">
      <div className="degrees_top">
        <h1 className="degrees_left">Degrees Table</h1>
        <div className="degrees_right">
          <AddDegreePopup />
        </div>
      </div>
      <div className="degrees_table">
        <DegreeTable />
      </div>
    </div>
  );
}

export default Degrees;
