import React, { useEffect, useState } from "react";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { MatxLoading } from "app/components";
import { Edit } from "@mui/icons-material";
import Pagination from "Components/Pagination/Pagination";
import { getAllProviders } from "Redux/Providers/ProvidersActions";
import EditProvidersPopup from "./EditProvidersPopup";
import ViewProvidersPopup from "./ViewProvidersPopup";

function ProvidersTable() {
  const StyledTable = styled(Table)(({ theme }) => ({
    whiteSpace: "pre",
    "& thead": {
      "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
      "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
  }));

  const { allProviders, getAllProvidersLoading } = useSelector(
    (state) => state.providersReducer
  );

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [offset, setoffset] = useState(0);
  useEffect(() => {
    dispatch(getAllProviders(limit, offset));
  }, []);
  useEffect(() => {
    dispatch(getAllProviders(limit, offset));
  }, [limit, offset]);

  return (
    <div>
      {!getAllProvidersLoading ? (
        <Box width="100%" overflow="auto">
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell align="left">Name</TableCell>
                <TableCell align="center">Institution</TableCell>
                <TableCell align="center">Commission</TableCell>
                <TableCell align="center">Country</TableCell>
                <TableCell align="center">City</TableCell>
                <TableCell align="center">Medicine</TableCell>
                <TableCell align="center">Link</TableCell>
                <TableCell align="center">Procedure</TableCell>
                <TableCell align="center">Contact</TableCell>
                <TableCell align="center">Online Program</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {allProviders?.providers?.map((provider, index) => (
                <TableRow key={index}>
                  <TableCell
                    align="left"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {provider?.provider}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {provider?.institution}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {provider?.commission}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {provider?.country}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {provider?.city}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {provider?.medecine ? "true" : "false"}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {provider?.search_tool}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {provider?.application_procedure}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {provider?.contact}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {provider?.online_program ? "true" : "false"}
                  </TableCell>
                  <TableCell align="right">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                        justifyContent: "flex-end",
                      }}
                    >
                      <EditProvidersPopup
                        provider={provider}
                        country={provider.country}
                        city={provider.city}
                      />
                      <ViewProvidersPopup
                        provider={provider}
                        country={provider.country}
                        city={provider.city}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
          <Pagination
            limit={limit}
            offset={offset}
            setOffset={setoffset}
            pageNumber={page}
            setPageNumber={setPage}
            hasPrev={allProviders.previous}
            hasNext={allProviders.next}
          />
        </Box>
      ) : (
        <MatxLoading />
      )}
    </div>
  );
}

export default ProvidersTable;
