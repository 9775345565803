import { axiosReq } from "app/utils/utils";
import { ProvidersActions } from "./ProvidersReducer";

export const getAllProviders = (limit, offset) => async (dispatch) => {
  dispatch(ProvidersActions.fetchAllProvidersRequest());
  dispatch(
    axiosReq(
      "get",
      process.env.REACT_APP_API + `/admin/pathwayProvider`,
      {},
      {},
      { limit: limit, offset: offset }
    )
  ).then((res) => {
    if (res.success) {
      dispatch(ProvidersActions.fetchAllProvidersSuccess(res.data));
    } else {
      dispatch(
        ProvidersActions.fetchAllProvidersFail(res.data.response.data.message)
      );
    }
  });
};

export const editProviderById =
  (id, data, limit, offset) => async (dispatch) => {
    const url = process.env.REACT_APP_API + `/admin/pathwayProvider/${id}`;
    dispatch(ProvidersActions.editProviderRequest());
    dispatch(axiosReq("patch", url, {}, data)).then((res) => {
      if (res.success) {
        dispatch(ProvidersActions.editProviderSuccess());
        dispatch(getAllProviders(10, 0));
      } else dispatch(ProvidersActions.editProviderFail(res.data.message));
    });
  };

export const addProvider = (handleClose, data) => async (dispatch) => {
  const url = process.env.REACT_APP_API + `/admin/pathwayProvider`;
  dispatch(ProvidersActions.addProviderRequest());
  dispatch(axiosReq("post", url, {}, data)).then((res) => {
    if (res.success) {
      dispatch(ProvidersActions.addProviderSuccess());
      handleClose();
      dispatch(getAllProviders(10, 0));
    } else dispatch(ProvidersActions.addProviderFail(res.data.message));
  });
};
