import thunk from "redux-thunk";
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import UserSlice from "./User/UserReducers";
import StatsSlice from "./Stats/StatsReducers";
import StudentsSlice from "./Students/StudentsReducers";
import CounselorsSlice from "./Counselors/CounselorsReducers";
import MajorCategoriesSlice from "./MajorCategories/MajorCategoriesReducers";
import DegreeSlice from "./Degrees/DegreesReducer";
import ProvidersSlice from "./Providers/ProvidersReducer";
import UniversitiesSlice from "./Universities/UniversitiesReducer";
import SalesSlice from "./Sales/SalesReducers";
import LocationsSlice from "./Locations/LocationsReducer";
import OfficesSlice from "./Offices/OfficesReducer";
import LocationSlice from "./Location/LocationReducer";
import AdminSourcesSlice from "./Sources/SourcesReducers";
import CrmSlice from "./Crm/CrmReducers";
import NewsLetterSlice from "./NewsLetter/NewsLetterReducers";

const RootReducer = combineReducers({
  // Add reducers here
  User: UserSlice.reducer,
  Stats: StatsSlice.reducer,
  Students: StudentsSlice.reducer,
  CounselorsReducer: CounselorsSlice.reducer,
  MajorCategories: MajorCategoriesSlice.reducer,
  degreeReducer: DegreeSlice.reducer,
  Locations: LocationsSlice.reducer,
  providersReducer: ProvidersSlice.reducer,
  LocationReducer: LocationSlice.reducer,
  UniversitiesReducer: UniversitiesSlice.reducer,
  SalesReducer: SalesSlice.reducer,
  CrmReducer: CrmSlice.reducer,
  officesReducer: OfficesSlice.reducer,
  SourcesReducers: AdminSourcesSlice.reducer,
  NewsLetterReducer: NewsLetterSlice.reducer,
});

export const Store = configureStore({
  reducer: RootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});
