import { Autocomplete, styled, TextField } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import React, { Fragment } from "react";

const AutoComplete = styled(Autocomplete)(() => ({
  width: "90%",
  marginBottom: "16px",
}));

const filter = createFilterOptions();

const DropDown2 = ({ suggestions, title, setselected, selected }) => {
  const [value, setValue] = React.useState(null);

  const handleChange = (_, newValue) => {
    if (newValue && newValue.inputValue) {
      setselected({ label: newValue.inputValue });
      return;
    }
    setValue(newValue);
    setselected(newValue);
  };

  return (
    <Fragment>
      <AutoComplete
        options={suggestions}
        getOptionLabel={(option) => option.label}
        value={selected}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField {...params} label={title} variant="outlined" fullWidth />
        )}
      />
    </Fragment>
  );
};

export default DropDown2;
