import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, Icon } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogActions from "@mui/material/DialogActions";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { Add } from "@mui/icons-material";
import { add, set } from "lodash";
import { addDegree } from "Redux/Degrees/DegreesActions";
import { MatxLoading } from "app/components";

const DialogTitleRoot = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
  "& .closeButton": {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogTitle = (props) => {
  const { children, onClose } = props;
  return (
    <DialogTitleRoot disableTypography>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className="closeButton"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitleRoot>
  );
};

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  "&.root": { padding: theme.spacing(2) },
}));

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  "&.root": { margin: 0, padding: theme.spacing(1) },
}));

function AddDegreePopup() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const [documents, setDocuments] = useState([""]);
  const [degreeName, setDegreeName] = useState("");

  const handleAddDocument = () => {
    setDocuments([...documents, ""]);
  };

  const handleRemoveDocument = (index) => {
    let temp = [...documents];
    temp.splice(index, 1);
    setDocuments(temp);
  };

  const dispatch = useDispatch();

  const { addDegreeError, addDegreeLoading } = useSelector(
    (state) => state.degreeReducer
  );

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen} color="primary">
        <span>Add Degree</span>
      </Button>

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Add Degree
        </DialogTitle>

        <DialogContent dividers>
          <Typography gutterBottom>
            <div
              style={{
                paddingBottom: "0.5rem",
                width: "30rem",
              }}
            >
              <TextField
                id="outlined-basic"
                label={"Degree name"}
                variant="outlined"
                value={degreeName}
                style={{ width: "100%" }}
                onChange={(e) => {
                  setDegreeName(e.target.value);
                }}
              />
            </div>
          </Typography>
          <Typography gutterBottom>
            <span style={{ fontSize: "1.2rem" }}>Documents Needed:</span>
            <br />
          </Typography>
          <Typography gutterBottom>
            <div
              style={{
                width: "30rem",
                fontSize: "1.2rem",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                paddingTop: "0.5srem",
              }}
            >
              {documents?.map((doc, i) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    id="outlined-basic"
                    label={"document " + (i + 1)}
                    variant="outlined"
                    style={{ width: "100%" }}
                    value={doc}
                    onChange={(e) => {
                      setDocuments(
                        documents.map((d, index) =>
                          index === i ? e.target.value : d
                        )
                      );
                    }}
                  />
                  {documents.length > 1 && (
                    <IconButton onClick={handleRemoveDocument}>
                      <Icon color="error">delete</Icon>
                    </IconButton>
                  )}
                </div>
              ))}
            </div>
          </Typography>
          <Button
            onClick={handleAddDocument}
            variant="contained"
            style={{ marginTop: "1rem", width: "30rem" }}
            color="primary"
          >
            Add Document
          </Button>
          {addDegreeError && (
            <Typography
              gutterBottom
              style={{ color: "red", marginTop: "1rem" }}
            >
              {addDegreeError}
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            color="warning"
            variant="contained"
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={documents.includes("") || degreeName === ""}
            onClick={() => {
              dispatch(addDegree(degreeName, documents, handleClose));
            }}
          >
            {addDegreeLoading ? <CircularProgress /> : "Save changes"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddDegreePopup;
