import { Button, Icon, IconButton, TextField } from "@mui/material";
import {
  addFaculty,
  editFaculty,
} from "Redux/Universities/UniversitiesActions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PopUpDel from "Components/DeletePop/PopUpDel";

function EditFaculty({ oldFaculties }) {
  const {
    addFacultyLoading,
    addFacultyError,
    addFacultySuccess,
    editFacultyError,
    editFacultyLoading,
    editFacultySuccess,
  } = useSelector((state) => state.UniversitiesReducer);

  const dispatch = useDispatch();
  const [add, setAdd] = useState(false);
  const [error, setError] = useState("");
  const [edit, setEdit] = useState({});
  const [newFaculty, setNewFaculty] = useState("");
  const { id } = useParams();
  const [deleteP, setDeleteP] = useState(false);
  const [clicked, setClicked] = useState(false);

  const handleSave = (deg) => {
    if (!edit.name) {
      setError("Please fill all the fields");
    } else {
      setEdit({ step: -1 });
      setError("");
      dispatch(editFaculty(deg.id, { name: edit.name }, id));
    }
  };

  const handleAdd = () => {
    if (!newFaculty) {
      setError("Please fill all the fields");
    } else {
      setClicked(true);
      setError("");
      const fac = {
        name: newFaculty,
        university: id,
      };
      dispatch(addFaculty(fac, id));
    }
  };

  const handleDelete = () => {
    setDeleteP(false);
  };

  useEffect(() => {
    if (addFacultyError) {
      setError(addFacultyError);
    } else if (editFacultyError) {
      setError(editFacultyError);
    }
  }, [addFacultyError, editFacultyError]);

  useEffect(() => {
    if (addFacultySuccess && clicked) {
      setAdd(false);
      setNewFaculty("");
      setClicked(false);
    }
  }, [clicked, addFacultySuccess]);
  return (
    <div className="add_faculty_main_div">
      <div className="add_facutly_header">
        <h3>Edit Faculties</h3>

        <Button
          variant="contained"
          onClick={() => {
            setEdit({});
            setAdd(true);
          }}
        >
          Add Faculty
        </Button>
      </div>

      <div className="add_major_body_div2">
        {oldFaculties?.map((deg, index) => {
          return (
            <ul>
              <li>
                <div className="add_major_body_div2_li_div">
                  <div style={{ minWidth: "8rem" }}>{deg.name}</div>
                  <IconButton>
                    <Icon
                      color="primary"
                      onClick={() => {
                        setAdd(false);
                        setEdit({ step: index, name: deg.name });
                      }}
                    >
                      edit
                    </Icon>
                  </IconButton>
                </div>
                {edit.step === index && (
                  <div className="add_major_body_div2_li_div">
                    <TextField
                      style={{ width: "30%" }}
                      value={edit.name}
                      label="Faculty Name"
                      onChange={(e) => {
                        setEdit({ ...edit, name: e.target.value });
                      }}
                    />
                    {error && <div className="error_div">{error}</div>}

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleSave(deg);
                      }}
                      disabled={editFacultyLoading || addFacultyLoading}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setEdit({ step: -1 });
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
              </li>
            </ul>
          );
        })}
      </div>
      {deleteP && (
        <PopUpDel setopen={setDeleteP} open={deleteP} func={handleDelete} />
      )}
      <div className="edit_faculty_body">
        {add && (
          <div className="add_faculty_body_div">
            <h3>Add Faculty:</h3>
            <TextField
              style={{ width: "30%" }}
              label="Faculty Name"
              value={newFaculty}
              onChange={(e) => {
                setNewFaculty(e.target.value);
              }}
            />
            {error && <div className="error_div">{error}</div>}

            <>
              <Button
                variant="contained"
                onClick={() => handleAdd()}
                disabled={addFacultyLoading || editFacultyLoading}
              >
                Add
              </Button>

              {
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    setAdd(false);
                  }}
                >
                  Cancel
                </Button>
              }
            </>
          </div>
        )}
      </div>
    </div>
  );
}

export default EditFaculty;
